.news-aside{
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 12px;
    background: rgba(var(--bg-k),1.0);

    &::before{
        content: attr(data-title);
        display: inline-block;
        font-family: var(--font-family);
        font-weight: 600;
        font-size: clamp(18px, 1.746vw, 22px);
        line-height: 127%;
        color: rgba(var(--cl-b),.95);
        padding-bottom: 16px;
        border-bottom: solid 1px var(--br-a);
        width: 100%;
    }

    &__list{
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__item{
        display: flex;
    }

    &__link{
        display: flex;
        align-items: center;
        gap: 8px;
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.01em;
        color: rgba(var(--cl-b),.95);
        transition: all .3s;

        svg,img{
            width: 24px;
            height: 24px;
        }

        &:hover{
            color: rgba(var(--cl-c),1.0);
        }
      }
}