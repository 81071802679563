.main-btn {
  transition: all .4s;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 8px;
  background: rgba(var(--bg-f),1.0);
  padding: 14px 24px;
  border-radius: 8px;
  width: fit-content;
  height: 46px;
  position: relative;
  z-index: 1;
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  text-align: center;
  color: rgba(var(--cl-a),1.0);

  img,svg{
    position: relative;
    display: flex;
    width: 18px;
    aspect-ratio: 1;
    margin-left: -8px;
  }

  &:hover{
    box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.3);
  }

  &--transparent{
    background: transparent;
    border: solid 1px var(--br-c);
    color: rgba(var(--cl-c),1.0);

    &:hover{
      background: rgba(var(--bg-f),1.0);
      color: rgba(var(--cl-a),1.0);
    }
  }

  &--gray{
    background: rgba(var(--bg-d),1.0);
    border: solid 1px var(--br-b);
    color: rgba(var(--cl-f),1.0);

    &:hover{
      border-color: var(--br-c);
      background: rgba(var(--bg-f),1.0);
      color: rgba(var(--cl-a),1.0);
    }
  }
}






