.deskr-section{
    margin-bottom: clamp(48px, 5.7143vw, 72px);
    
    &__box{
        border-top: solid 1px var(--br-a);
        padding-top:clamp(48px, 5.7143vw, 72px);
        display: flex;
        flex-direction: column;
        gap: 30px;

        p{
            font-size: 16px;
        }
    }
}