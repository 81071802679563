.interesting-slider {
  padding: 20px 20px 13px;

  &__top {
    display: flex;
    margin-bottom: 20px;
    gap: 24px;
    align-items: center;
  }

  &__title {
    flex-grow: 1;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 24px;
    line-height: 133%;
    color: rgba(var(--cl-b), 0.95);
  }

  .swiper {
    
    &-btn {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 5;

      svg,
      img {
        transition: all 0.3s;
        color: rgba(var(--cl-f), 1);
        width: 24px;
        height: 24px;
      }

      &:hover {
        svg,
        img {
          color: rgba(var(--cl-c), 1);
        }
      }

      &.prev {
        svg,
        img {
          transform: scaleX(-1);
        }
      }

      &.swiper-button-lock {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
      }
    }
  }
}
