.search-section{
    margin-bottom: clamp(26px, 2.5397vw, 32px);

    @include mobile{
        margin-bottom: 16px;
    }
    
    &__box{
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .search-form{
        @include tablet{
            width: calc(100% + (var(--container-offset) * 2));
            margin-left: calc(var(--container-offset) * -1);
            border-radius: 0;
            padding: 16px var(--container-offset);
        }

        &__bg{
            @include tablet{
                border-radius: 0;
            }
        }
    }
}