.news {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  border-radius: 12px;
  background: rgba(var(--bg-k), 1);

  .main-top {
    padding-bottom: 16px;
    grid-column: 1/-1;
    border-bottom: solid 1px var(--br-a);

    .main-link{
      margin-right: 2px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: 575px;
    overflow-y: auto;
    margin-right: -16px;

    &::-webkit-scrollbar-thumb {
      background-color: #e1e3e6;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 8px;
      height: 48px;
      border-radius: 4px;
    }
  }

  &__item {
    width: 100%;
  }

  &__link {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &:hover {
      .news__title {
        color: rgba(var(--cl-c), 1);
      }
    }
  }

  &__title {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: rgba(var(--cl-b), 0.95);
    transition: all 0.3s;
  }

  &__date {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    color: rgba(var(--cl-b), 0.58);
  }
}
