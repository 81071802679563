.main-news{
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__title{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: clamp(22px, 2.2222vw, 28px);
        line-height: 129%;
        color: rgba(var(--cl-b),.95);
        padding-bottom: 16px;
        border-bottom: solid 1px var(--br-a);
        width: 100%;
    }

    &__list{
        display: flex;
        flex-direction: column;
        gap: clamp(16px, 1.5873vw, 20px);
    }

    .main-btn{
        margin-top: clamp(8px, 1.2698vw, 16px);
    }
}