.main-weather{
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__list{
        display: flex;
        align-items: center;
        padding: 0 clamp(12px, 1.4286vw, 18px);
        justify-content: space-between;
    }

    &__subtitle{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.01em;
        color: rgba(var(--cl-b),.95);
    }

    &__inner{
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    &__wrapp{
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    &__chart{
        height: 94px;
        width: 100%;

        canvas{
            width: 100%;
            height: 100%;
        }
    }
}