.advertisements-card{
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__title{
        display: flex;
        align-items: center;
        gap: 8px;
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 18px;
        line-height: 144%;
        color: rgba(var(--cl-b),.95);

        svg,img{
            width:24px;
            height:24px;
            margin-top: -3px;
        }
    }

    &__list{
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    &__link{
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: rgba(var(--cl-b),.95);
        transition: all .3s;

        &:hover{
            color: rgba(var(--cl-c),1.0);
        }
    }
}