.mobile {
  position: fixed;
  top: var(--header-height);
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(var(--bg-a), 1);
  z-index: 80;
  overflow: hidden;
  transition: 0.3s ease;
  max-width: 460px;
  transform: translateX(110%);
  padding: 32px 24px 32px;
  border-top: solid 1px rgba(62, 62, 77, 0.6);
 

  @include  for-tablet{
    display: contents;
  }

  &.active {
    display: flex;
    transform: translateX(0);

    @include  tablet {
      overflow-y: auto;

      .header__nav ul {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .burger{
    position: absolute;
    z-index: 10;
    top: 20px;
    right: 23px;
  }

  &__box {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    width: 100%;
    align-items: center;

    @include  for-tablet{
      display: contents;
    }

  }

  @include tablet {
    .lang-box, .main-btn, .select{
      display: none;
    }

    .header__wrapp{
      display: contents;
    }

    .header__inner{
      flex-direction: column;
      gap: 20px;

      
    padding-bottom: calc(var(--header-height) + 20px);
    }

    .header-link{
      font-size: 18px;
      font-weight: 700;
    }

    .header__nav {
      order: -1;
      width: 100%;
      flex-direction: column;
      padding-bottom: 20px;

      &::before{
        content: none;
      }
     
      &:only-child{
        flex-grow: 1;
      }

      ul {
        opacity: 0;
        transition: 0.3s ease;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-gap: 10px;

        a, .menu-button {
          width: 100%;
          padding: 4px 0;
          justify-content: start;
          font-size: 18px;
          line-height: 120%;

          &.disabled{
            display: none;
          }
        }

        .menu-button{
          width: fit-content;
          justify-content: space-between;

          svg,img{
            position: absolute;
            top: 6px;
            right: -28px;
          }
        }

        .menu-item-has-children{
          width: 100%;
        }
      }

      .sub-menu{
 
        align-items: center;

        @include tablet{
          a{
            font-size: 16px;
          }
        }
      }
    }
  }
}
