.main-wind{
    display: flex;
    align-items: center;

    &__icon{
        display: flex;
        width: clamp(24px, 2.2222vw, 28px);
        height: clamp(24px, 2.2222vw, 28px);

        svg,img{
            width: 100%;
            height: 100%;
        }
    }

    &__value{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.01em;
        text-align: right;
        color: rgba(var(--cl-b),.95);
    }

    &__item{
        padding: 7px;
        border-radius: 0 8px 8px 0;
        overflow: hidden;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }
}