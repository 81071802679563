[data-btn-modal] {
  svg {
    pointer-events: none;
  }
}

.modal {
  margin: auto;
  transform: scale(.8);
  display: none;

  &.active {
    transform: scale(1);
  }

  .close {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    right: 0;
    top: 0;
    width: 48px;
    height: 48px;
    transition: all .3s;
    background: rgba(var(--bg-a),1.0);
    color: rgba(var(--cl-f),1.0);
    
    &:hover{
      color: rgba(var(--cl-c),1.0);
    }
  }

  .close svg {
    transition: all .2s linear;
    pointer-events: none;
    width: 24px;
    height: 24px;
  }
}
