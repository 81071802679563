.modal-form{
    display: flex;
    flex-direction: column;
    padding: 12px 0 0;
    gap: 13px;

    .select{
        margin-left: 12px;
    }

    &__box{
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        width: 100%;
        max-height: 680px;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}