.radio-checkbox {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  
    cursor: pointer;
    &:hover {
      span {
       color: rgba(var(--cl-c),1.0);
      }
    }
  
    input {
      position: absolute;
      visibility: hidden;
      z-index: -1;
      opacity: 0;
    }
  
    > span {
      padding: 6px 12px;
      border-radius: 8px;
      border: solid 1px transparent;
      background: transparent;
      transition: all 0.3s;
      cursor: pointer;
      white-space: nowrap;
      position: relative;
      z-index: 2;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      transition: all 0.3s;
      width: 100%;
      height: 100%;
      font-style: normal;
      font-family: var(--second-family);
      font-weight: 500;
      font-size: 14px;
      line-height: 143%;
      letter-spacing: 0.01em;
      text-align: center;
      color: rgba(var(--cl-a),1.0);
      
    }
  
    input {
      &:checked + span {
        border-color: var(--br-b);
      }

      &:checked + span:hover {
        border-color: rgba(var(--cl-c),1.0);
      }
    }
  }

  .bank-checkbox {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-top: solid 1px  var(--br-e);
    cursor: pointer;

    &:hover {
      background: #F6F5F7;
    }
  
    input {
      position: absolute;
      visibility: hidden;
      z-index: -1;
      opacity: 0;
    }
  
    &__content{
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 8px;
      padding: 12px;
    }

    &__icon{
      width: 28px;
      height: 28px;
      display: flex;
      grid-row: 1/4;

      img,picture{
        width: 100%;
        height: 100%;
      }
    }

    &__title{
      font-family: var(--font-family);
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.01em;
      color: rgba(var(--cl-b),.95);
    }

    &__geo{
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 12px;
      line-height: 133%;
      color: rgba(var(--cl-b),.58);
      display: flex;
      align-items: center;
      gap: 4px;

      svg{
        width: 16px;
        height: 16px;
        flex-shrink: 0;
      }
    }

    &__time{
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 12px;
      line-height: 133%;
      color: rgba(var(--cl-b),.58);
      display: flex;
      align-items: center;
      gap: 4px;

      svg{
        fill: rgba(var(--cl-b),.58);
        width: 14px;
        height: 14px;
        flex-shrink: 0;
        margin:0 1px;
      }
    }

    > span {
      border-radius: 8px;
      background: transparent;
      transition: all 0.3s;
      cursor: pointer;
      position: relative;
      z-index: 2;
      user-select: none;
      transition: all 0.3s;
      width: 100%;
      height: 100%;
    }
  
    input {
      &:checked + span {
       background: #FCF3E6;
      }
    }
  }
