.main-top{
    grid-gap: 15px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    &__filter{
        display: flex;
        align-items: center;
        gap: clamp(8px, 1.2698vw, 16px);

        h1,h2,h3,h4,h5,h6{
            margin-right: clamp(4px, .6349vw, 8px);
        }
    }

    &__label{
        padding: 8.5px 16px 8.5px 9.5px;
        display: flex;
        align-items: center;
        gap: 8px;
        background: rgba(var(--bg-j),1.0);
        border-radius: 8px;
        font-family: var(--second-family);
        font-weight: 500;
        font-size: 14px;
        line-height: 143%;
        letter-spacing: 0.01em;
        text-align: center;
        color:  rgba(var(--cl-b),.38);
        height: 32px;

        svg,img{
            width: 16px;
            height: 16px;
        }

        &.verify{
            background: rgba(var(--bg-m),.30);
        }
    }

    &__inner{
        width: 100%;
        display: flex;
        align-items: center;
        gap: 15px;
        justify-content: space-between;


        .rating{
            &__star{
                width: 22px;
                height: 22px;
            }

            &__item{
                margin-bottom: -3px;
            }
        }
    }

    &__wrapp{
        display: flex;
        align-items: center;
        gap: clamp(8px, 1.2698vw, 16px);
        z-index: 20;

        @include for-small-tablet{
            display: contents;
        }
    }

    &__subtitle{
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: rgba(var(--cl-b),.58);
    }
}