.popular-slider {
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;

  @include tablet{
    width: calc(100% + (var(--container-offset) * 2));
  }

  @include small-tablet{
   padding-bottom: 65px;
  }

  .swiper {
    &-container {
      width: 100%;
      @include tablet {
        
        padding: 0 var(--container-offset);
      }
    }

    &-wrapper{
      width: 100%;
    }

    &-slide {
      height: auto;
    }

    &-btn {
      border-radius: 100%;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 5;
      background: rgba(var(--bg-c),1.0);
      transition: all .3s;
      position: absolute;
      top: 50%;

      svg,
      img {
        color: #1A1918;
        width: 24px;
        height: 24px;
      }

      &:hover {
        background: rgba(var(--bg-e),1.0);
      }

      &.prev{
        left: 0;
        transform: translate(-50%, -50%);

        svg,img{
          transform: scaleX(-1);
        }

        @include tablet{
          left: calc(var(--container-offset) + 5px);
        }

        @include small-tablet{
          transform: translate(-100%, -100%);
          left: calc(50% - 8px);
        }
      }

      &.next{
        right: 0;
        transform: translate(50%, -50%);

        @include tablet{
          right: calc(var(--container-offset) + 5px);
        }

        @include small-tablet{
          transform: translate(100%, -100%);
          right: calc(50% - 8px);
        }
      }

      &.swiper-button-lock{
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
      }

      @include small-tablet{
        top: 100%;
      }
    }
  }
}
