.rating{
    display: flex;
    align-items: center;
    gap: 1px;

    &__star{
        display: block;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 15px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Cpath fill='%23FC0' d='m4.369 16.5 1.219-5.269L1.5 7.688l5.4-.47L9 2.25l2.1 4.969 5.4.468-4.088 3.544 1.22 5.269L9 13.706 4.369 16.5Z'/%3E%3C/svg%3E");

        &.half{
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Cpath fill='%23E9E8EB' d='m4.369 16.5 1.219-5.269L1.5 7.688l5.4-.47L9 2.25l2.1 4.969 5.4.468-4.088 3.544 1.22 5.269L9 13.706 4.369 16.5Z'/%3E%3Cg clip-path='url(%23a)'%3E%3Cpath fill='%23FC0' d='m4.369 16.5 1.219-5.269L1.5 7.688l5.4-.47L9 2.25l2.1 4.969 5.4.468-4.088 3.544 1.22 5.269L9 13.706 4.369 16.5Z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h9v18H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        }

        &.none{
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Cpath fill='%23E9E8EB' d='m4.369 16.5 1.219-5.269L1.5 7.688l5.4-.47L9 2.25l2.1 4.969 5.4.468-4.088 3.544 1.22 5.269L9 13.706 4.369 16.5Z'/%3E%3C/svg%3E");
        }
    }

    &__item{
        margin-left: 9px;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 14px;
        line-height: 143%;
        color: rgba(var(--cl-b),.58);
        margin-bottom: -2px;
        i{
            font-style: normal;
        }

        @include mobile{
            margin-left: 10px;
            font-size: 12px;
            line-height: 133%;
        }
    }
}