.parking-card{
    display: grid;
    grid-template-columns: auto 1fr;
    gap: clamp(16px, 1.5873vw, 20px);
    padding: 8px;
    border-radius: 12px;

    &:hover{
        background: rgba(var(--bg-c),.6);
        .parking-card__image{
            img,picture{
                transform: scale(1.03);
            }
        }
    }

    &__image{
        width: clamp(180px, 17.619vw, 222px);
        height: clamp(145px, 12.381vw, 156px);
        border-radius: 4px;
        overflow: hidden;

        img,picture{
            transition: all .3s;
            width: 100%;
            height: 100%;
        }

        @include mobile{
            width: 90px;
            height: 64px;
        }
    }

    &__box{
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    &__title{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: clamp(16px, 1.4286vw, 18px);
        line-height: 144%;
        color: rgba(var(--cl-b),0.95);
    }

    &__subtitle{
        font-family: var(--font-family);
        font-weight: 400;
        font-size: clamp(14px, 1.2698vw, 16px);
        line-height: 115%;
        color: rgba(var(--cl-b),0.95);
    }

    .rating{
        margin-bottom: auto;

        &__star{
            width: 18px;
            height: 18px;
        }

        @include mobile{
            margin-bottom: 4px;
        }
    }

    &__time{
        display: inline-flex;
        gap: 5px;
        align-items: center;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 14px;
        line-height: 143%;
        color: rgba(var(--cl-b),.58);

        @include mobile{
            line-height: 133%;
            font-size: 12px;
            gap: 6px;
        }

        svg,img{
            margin-top: -2px;
            width: 18px;
            flex-shrink: 0;
            height: 18px;

            @include mobile{
                width: 16px;
                height: 16px;
            }
        }

        svg{
            fill: #8D9099;
        }
    }

    &__geo{
        display: inline-flex;
        gap: 6px;
        align-items: center;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 14px;
        line-height: 143%;
        color: rgba(var(--cl-b),.58);
      
        svg,img{
            flex-shrink: 0;
            color: rgba(var(--cl-g),1.0);
            margin-top: -2px;
            margin-left: -1px;
            width: 20px;
            height: 20px;

            @include mobile{
                gap: 4px;
                width: 18px;
                height: 18px;
            }
        }

        @include mobile{
            line-height: 133%;
            font-size: 12px;
        }
    }

    &.active{
        background: rgba(var(--bg-c),1.0);
    }
}