.select {
  z-index: 25;
  position: relative;
  user-select: none;
  width: fit-content;
  transition: all 0.1s;
  background: transparent;
  min-width: 128px;
  padding: 11px 14px 11px 24px;
  border-radius: 8px;
  border: 1px solid var(--br-c);
  height: 46px;

  &__header {
    width: 100%;
    max-width: 100%;
    cursor: pointer;
    gap: 8px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 6;

    & > svg {
      transition: all 0.2s linear;
      flex-shrink: 0;
    }
  }

  &__current {
    flex-grow: 1;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    grid-gap: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all 0.3s;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.01em;
    text-align: center;
    color: rgba(var(--cl-c), 1);

    &:not(.select__current-icon) {
      svg,
      img {
        width: 18px;
        height: 18px;
      }
    }

    &-icon {
      width: 18px;
      height: 18px;
      stroke: rgba(var(--cl-c), 1);
      fill: transparent;
    }

    &:hover {
      color: rgba(var(--cl-b), 1);

      & + svg {
        stroke: rgba(var(--cl-b), 1);
      }
    }
  }

  &__body {
    right: -2px;
    position: absolute;
    z-index: 5;
    height: 0;
    top: calc(100% + 5px);
    overflow: hidden;
    transition: height 0.1s ease-in;
    left: -2px;
    box-shadow: initial;
    border-radius: 0 0 8px 8px;

    i {
      margin: 0;
      text-transform: uppercase;
      font-size: inherit;
      line-height: inherit;
    }

    &::before {
      position: absolute;
      content: "";
      height: calc(100% + 46px);
      border-radius: inherit;
      z-index: 1;
      bottom: 0;
      left: 0;
      width: 100%;
      border-radius: 8px;
      box-shadow: 0 5px 16px 0 rgba(1, 0, 73, 0.08);
      opacity: 0;
      pointer-events: none;
      background: rgba(var(--bg-a), 1);
      border: solid 1px var(--br-a);
    }
  }

  &__list {
    max-height: 150px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 0 0 10px;
    z-index: 3;
    position: relative;

    &::-webkit-scrollbar-thumb {
      background-color: #8E8B8A;
      border-radius: 100px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 100px;
    }
  }

  &__option {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    width: 100%;
    cursor: pointer;
    transition: all 0.3s;
    padding: 0 14px;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.01em;
    text-align: center;
    color: rgba(var(--cl-b), 1);
    height: 28px;
    position: relative;

    &:hover {
      color: var(--cl-a);
    }

    a {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
    }

    &[data-id="2"] {
      padding-top: 0;
    }
  }

  &__text {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.01em;
    text-align: center;
    color: rgba(var(--cl-b), 0.95);
    white-space: nowrap;
  }
}

.select.active {
  z-index: 25;
  border-radius: 8px 8px 0 0;
  border-color: transparent;

  .select {
    &__list {
      border-radius: 0 0 8px 8px;
    }

    &__body {
      top: 100%;
      left: 0;
      right: 0;
      overflow: initial;

      &::before {
        opacity: 1;
      }
    }
  }

  .select__header > svg {
    transform: scaleY(-1);
  }
}

.city-select {
  padding: 5px 0 7px;
  min-width: 100px;
  height: 36px;
  border: none;

  .select {
    &__header {
      width: fit-content;
    }

    &__current {
      color: rgba(var(--cl-b), 1);
      &-icon {
        stroke: rgba(var(--cl-b), 1);
        fill: transparent;
      }

      &:hover {
        color: rgba(var(--cl-c), 1);

        & + svg {
          stroke: rgba(var(--cl-c), 1);
        }
      }
    }

    &__body {
      &::before {
        display: none;
      }

      width: calc(100% + 30px);
    }

    &__list {
      box-shadow: 0 5px 16px 0 rgba(1, 0, 73, 0.08);
      background: rgba(var(--bg-a), 1);
      border: solid 1px var(--br-a);
      padding: 8px 0;
    }

    &__option {
      &:hover {
        color: rgba(var(--cl-c), 1);
      }
    }
  }

  &.active {
    .select {
      &__list {
        border-radius: 8px;
      }
      &__body {
        top: calc(100% + 5px);
        left: -15px;
        right: -15px;
      }
    }
  }
}

.lang-select {
  position: relative;

  .select {
    &__body {
      left: -1px;
      right: -1px;
      max-height: 156px;
    }

    &__list {
      padding: 0 0 0px;
      max-height: 139px;

      &::-webkit-scrollbar-thumb {
        background-color: #84868f;
        border-radius: 2px;
      }

      &::-webkit-scrollbar-track {
        background-color: #535662;
        border-radius: 2px;
      }

      &::-webkit-scrollbar {
        width: 3px;
        border-radius: 2px;
      }
    }

    &__option {
      padding: 0 26px 0 24px;

      &:hover {
        color: rgba(var(--cl-c), 1);
      }
    }
  }

  &.active {
    top: -90px;

    .select {
      &__current {
        color: rgba(var(--cl-b), 0.58) !important;

        &-icon {
          stroke: rgba(var(--cl-b), 0.58) !important;
        }
      }

      &__list {
        border-radius: 0 0 8px 8px;
      }

      &__body {
        overflow: initial;
        left: -1px;
        right: -1px;
        border-color: var(--br-a);
        padding-bottom: 10px;

        &::before {
          opacity: 1;
        }
      }
    }
  }
}

.directory-select {
  min-width: 184px;
  padding: 11px 14px 11px 16px;
  border-color: var(--br-a);

  &:hover {
    &:not(.active) {
      border-color: rgba(var(--cl-c), 1);
    }
  }

  .select {
    &__option {
      padding: 0 16px;
      &:hover {
        color: rgba(var(--cl-c), 1);
      }
    }

    &__current {
      &:hover {
        color: rgba(var(--cl-c), 1);

        & + svg {
          stroke: rgba(var(--cl-c), 1);
        }
      }
    }
  }
}

.filter-select {
  min-width: 102px;
  padding: 5px 8px 6px 16px;
  border-color: var(--br-a);
  height: 32px;

  &:hover {
    &:not(.active) {
      border-color: rgba(var(--cl-c), 1);
    }
  }

  .select {
    &__header {
      &-icon {
        display: flex;
        width: 18px;
        height: 18px;
        flex-shrink: 0;
        margin-left: -5px;
        color: rgba(var(--cl-c), 1);

        svg,
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    &__body {
      &::before {
        height: calc(100% + 32px);
      }
    }

    &__option {
      padding: 0 16px;
      font-family: var(--second-family);
      font-weight: 500;
      font-size: 14px;
      line-height: 143%;
      color: rgba(var(--cl-h), 1);
      height: 28px;
      line-height: 100%;
      text-align: start;

      &:hover {
        color: rgba(var(--cl-c), 1);
      }
    }

    &__current {
      font-family: var(--second-family);
      font-weight: 500;
      font-size: 14px;
      line-height: 143%;
      color: rgba(var(--cl-h), 1);

      &:hover {
        color: rgba(var(--cl-c), 1);

        & + svg {
          stroke: transparent;
          fill: rgba(var(--cl-c), 1);
        }
      }

      &-icon {
        fill: rgba(var(--cl-f),1.0);
        stroke: transparent;
      }
    }
  }
}

.exchange-select {
  min-width: 102px;
  padding: 10px 18px 10px 12px;
  border-color: var(--br-b);
  border-radius: 4px;

  &:hover {
    &:not(.active) {
      border-color: rgba(var(--cl-c), 1);
    }
  }

  .select {
    &__body{
      border-radius: 0 0 4px 4px;
      &::before{
        border-radius: 4px;
      }
    }
    &__option {
      padding: 0 18px 0 12px;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: rgba(var(--cl-b),.95);
      height: 26px;

      &:hover {
        color: rgba(var(--cl-c), 1);
      }

      img{
        width: 24px;
        height: 16px;
        margin-top: -1px;
      }
    }

    &__current {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: rgba(var(--cl-h), 1);

      & > img{
        width: 24px;
        height: 16px;
        margin-top: -1px;
      }

      &:hover {
        color: rgba(var(--cl-c), 1);

        & + svg {
          filter: brightness(100%);
          stroke: transparent;
        }
      }

      &-icon {
        filter: brightness(40%);
        stroke: transparent;
      }
    }
  }
}

.convert-select {
  min-width: 102px;
  padding: 15px 14px 14px 0px;
  border-color: transparent;
  border-radius: 4px;
  height: 56px;
  min-width: 112px;
  position: static;

  &::before{
    position: absolute;
    content: '';
    z-index: 2;
    border: solid 1px #8E8B8A;
    inset: -1px;
    pointer-events: none;
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
  }

  .select {
    &__body{
      border-radius: 4px;

      &::before{
        display: none;
      }
    }

    &__list{
      padding: 8px 4px 8px 0;
      max-height: 416px;
      box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
      background: rgba(var(--bg-a),1.0);
    }
    
    &__option {
      padding: 8px 12px;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: rgba(var(--cl-h),1);
      height: 40px;
      gap: 4px;

      &:hover {
        color: rgba(var(--cl-h),1);
        background: rgba(29, 27, 32, 0.08);
      }

      img{
        width: 0px;
        height: 0px;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
      }

      span{
        pointer-events: none;
      }

      &.active{
        opacity: .38;
      }
    }

    &__current {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: rgba(var(--cl-h), 1);

      & > img{
        width: 24px;
        flex-shrink: 0;
        height: 18px;
        margin-top: -1px;
      }

      &:hover {
        color: rgba(var(--cl-c), 1);

        & + svg {
          filter: brightness(100%);
          stroke: transparent;
        }
      }

      &-icon {
        filter: brightness(40%);
        stroke: transparent;
      }
    }
  }

  &.active{
    &::before{
      opacity: 1;
      visibility: visible;
    }

    .select{
      &__body{
        left: 0;
        right: -1px;
        top: calc(100% + 1px);
      }

      &__list{
        border-radius: 4px;
      }
    }
  }
}

.search-select {
  padding: 11px 18px 11px 16px;
  min-width: 130px;
  border: none;
  border-radius: 4px;
  flex-shrink: 0;

  @include mobile{
    min-width: 100px;
  }

  .select {

    &__current {
      color: rgba(var(--bg-h),1.0);

      &-icon {
        stroke: rgba(var(--cl-b), 1);
        fill: transparent;
      }

      &:hover {
        color: rgba(var(--cl-c), 1);

        & + svg {
          stroke: rgba(var(--cl-c), 1);
        }
      }
    }

    &__body {
      &::before {
        display: none;
      }
    }

    &__list {
      box-shadow: 0 5px 16px 0 rgba(1, 0, 73, 0.08);
      background: rgba(var(--bg-a), 1);
      border: solid 1px var(--br-a);
      padding: 8px 0;
    }

    &__option {
      padding: 0 16px;
      &:hover {
        color: rgba(var(--cl-c), 1);
      }
    }
  }

  &.active {
    .select {
      &__list {
        border-radius: 4px;
      }
      &__body {
        top: 100%;
        left: -1px;
        right: -1px;
      }
    }
  }
}