html {
  box-sizing: border-box;
  width: 100%;
  max-width: 100dvw;
  // scroll-behavior: smooth;
  will-change: font-size;
  font-size: var(--rem-function);
}

body {
  position: relative;
  overflow-x: hidden;
  width: 100%;
  // min-height: -webkit-fill-available;
  margin: 0;
  will-change: font-size;
  color: #2f2f2f;
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  background-color: var(--body-bg);
  -webkit-text-size-adjust: 100%;
  // for text resize

  &.fixed-body {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    // disable scroll
  }
}

main {
  min-width: 0 !important;
  padding-top: var(--header-height);

  &.no-padding {
    padding: 0;
  }
}

section:last-of-type {
  margin-bottom: clamp(48px, 5.7143vw, 72px);
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  display: inline-flex;
  margin: 0;

  font-style: inherit;
  font-weight: inherit;
  font-family: inherit;
  color: inherit;
  text-decoration: none;

  cursor: pointer;
}

button {
  display: inline-flex;
  margin: 0;
  padding: 0;

  line-height: initial;
  color: inherit;

  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

ul,
ol,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
span,
button,
p {
  margin: 0;
  padding: 0;
  color: var(--cl-b);
  font-family: var(--font-family);
  font-style: normal;
  line-height: 110%;
}

p {
  font-weight: 400;
  font-size: clamp(14px, 1.2698vw, 16px);
  line-height: 150%;
  color: rgba(var(--cl-b),.58);
}

img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

picture {
  display: inline-flex;
}

label {
  display: inline-flex;
}

input {
  width: 100%;

  background-color: transparent;
  border: none;
  outline: none;

  &::placeholder {
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
  }

  &[type="number"] {
    appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;

    appearance: none;
  }
}

textarea {
  border: none;
  outline: none;
  resize: none;
}

fieldset {
  margin: 0;
  padding: 0;

  border: none;
}

.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;

  white-space: nowrap;

  border: 0;

  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.container {
  position: relative;

  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
  padding: 0 var(--container-offset);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid rgba(17, 116, 166, 0.5);
  -webkit-text-fill-color: #1d1d1d;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 174, 255, 0) 50%,
    rgba(255, 255, 255, 0) 51%,
    rgba(0, 174, 255, 0) 100%
  );
}

.panel,
[data-content] {
  z-index: 20;

  max-height: 0;
  overflow: hidden;

  transition: max-height 0.2s;
}

[data-tab-content],
[data-inner-content] {
  display: none;

  &.active {
    display: block;
  }
}

[data-aside-content] {
  display: none;

  &.active {
    display: flex;
  }
}

.dis-scroll {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  overflow: hidden;

  overscroll-behavior: none !important;
  scroll-behavior: none !important;
}

.overlay {
  @include overlay;
}

* {
  &::-webkit-scrollbar {
    width: Max(8px, 8px);
    cursor: pointer;

    @include small-tablet {
      display: none;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb);
    cursor: pointer;
    transition: all 0.2s;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track);
  }

  &::-webkit-scrollbar-button {
    display: none;
    background-color: transparent;
    box-shadow: none;
  }
}

  main{
    ul:not([class]) {
      display: flex;
      flex-direction: column;
      grid-gap: 3px;
    
      li {
        display: inline-flex;
        grid-gap: 10px;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: var(--cl-c);
    
        b,
        strong {
          font-weight: 700;
        }
    
        &::before {
          content: "";
          flex-shrink: 0;
          display: block;
          width: 3px;
          align-self: center;
          height: 3px;
          margin-top: 1px;
          border-radius: 100%;
          background-color: var(--bg-f);
        }
      }
    }
  }

.editor {
  p {
    margin-bottom: 16px;
    i {
      font-style: normal;
      text-decoration: underline;
      text-decoration-skip-ink: none;
    }
  }

  small {
    font-size: 12px;
    line-height: 140%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: clamp(18px, 2.0478vw, 24px);
    color: var(--cl-a);

    i {
      font-style: normal;
      color: var(--cl-d);
    }
  }

  h1 {
    font-size: clamp(36px, 4.4369vw, 52px);
    font-weight: 500;
    line-height: 110%;
    letter-spacing: -0.01em;
  }

  h2 {
    font-size: clamp(30px, 3.5836vw, 42px);
    font-weight: 600;
    line-height: 140%;
  }

  h3,
  h4 {
    font-size: clamp(19px, 2.3891vw, 28px);
    font-weight: 600;
    line-height: 120%;
  }

  h5,
  h6 {
    font-weight: 600;
    line-height: 120%;
    font-size: clamp(17px, 2.0478vw, 24px);
  }

  *:last-child {
    margin-bottom: 0 !important;
  }

  ul {
    margin-bottom: clamp(16px, 1.3889vw, 20px);
  }

  ol:not([class]) {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    margin-bottom: clamp(16px, 1.3889vw, 20px);
    counter-reset: li;

    li {
      counter-increment: li;

      font-family: var(--font-family);
      font-weight: 400;
      font-size: clamp(14px, 1.3652vw, 16px);
      line-height: 115%;
      color: var(--cl-c);

      &::before {
        content: counter(li) ".";
        display: inline-block;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: clamp(16px, 1.5358vw, 18px);
        line-height: 115%;
        color: var(--cl-c);
        margin-right: 6px;
      }
    }
  }

  img {
    width: 100%;
    margin-top: clamp(4px, 1.3652vw, 16px);
    margin-bottom: clamp(18px, 2.7304vw, 32px);
    border-radius: clamp(10px, 1.3652vw, 16px);
    max-height: 420px;
    min-height: 160px;

    &:first-child{
      margin-top: 0;
    }
  }
}
