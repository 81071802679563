.hero-section {
  margin-bottom: 28px;

  &__bg {
    position: absolute;
    z-index: -1;
    border-radius: 12px;
    overflow: hidden;
    inset: 0;
    pointer-events: none;
    background: rgba(var(--bg-e), 1);

    &::before {
      position: absolute;
      content: "";
      inset: 0;
      z-index: 1;
      pointer-events: none;
      border-radius: inherit;
      background: rgba(38, 38, 38, 0.2);
    }

    img,
    picture {
      width: 100%;
      height: 100%;
    }
  }

  &__box {
    position: relative;
    z-index: 2;
    padding: 20px 0 0;
    border-radius: 12px;
    min-height: 360px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 16px;
  }

  &__bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  &__weather{
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__wrapp{
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__valute{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: rgba(var(--cl-a),1.0);

    i{
      font-style: normal;
    }
  }

  &__value{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: rgba(var(--cl-a),1.0);

    i{
      font-style: normal;
    }
  }

  &__icon{
    display: flex;
    width: 24px;
    height: 24px;

    svg{
      width: 100%;
      height: 100%;
    }
  }

  &__text{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: rgba(var(--cl-a),1.0);

  }

  &__title{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: clamp(36px, 4.5238vw, 57px);
    line-height: 112%;
    letter-spacing: 0em;
    color: rgba(var(--cl-a),1.0);
    padding: 0 32px;
  }

  &__inner{
    border-radius: 0 0 12px 12px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px 32px;
    backdrop-filter: blur(30px);
    background: rgba(255, 255, 255, 0.01);
  }

  .search-form{
      padding: 0;
      border-radius: 0;
  }
}
