.main-poster {
  display: flex;
  gap: clamp(20px, 1.9048vw, 24px);
  flex-direction: column;

  &__box {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: clamp(20px, 3.1746vw, 40px);
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .main-poster__title,
    .main-poster__list {
      margin-bottom: 4px;
    }
  }

  p {
    color: rgba(var(--cl-b), 0.95);
  }

  &__image {
    width: clamp(450px, 40.5556vw, 511px);
    height: clamp(500px, 43.4921vw, 548px);
    border-radius: 12px;
    overflow: hidden;

    img,
    picture {
      width: 100%;
      height: 100%;
    }
  }

  &__map {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .main-poster__image {
      height: clamp(300px, 26.6667vw, 336px);
    }
  }

  &__deskr {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: rgba(var(--cl-b), 0.38);
  }

  &__wrapp{
    display: flex;
    flex-direction: column;
    gap: 16px;

    .main-poster__subtitle,
    .main-poster__list {
      margin-bottom: 4px;
    }
  }

  &__title {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: clamp(26px, 2.8571vw, 36px);
    line-height: 122%;
    color: rgba(var(--cl-b), 0.95);
  }

  &__subtitle {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: clamp(22px, 2.2222vw, 28px);
    line-height: 129%;
    color: rgba(var(--cl-b), 0.95);
  }

  &__pretitle {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: clamp(16px, 1.4286vw, 18px);
    line-height: 144%;
    color: rgba(var(--cl-b), 0.95);
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__text {
    display: flex;
    align-items: center;
    gap: clamp(8px, 0.9524vw, 12px);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: clamp(15px, 1.2698vw, 16px);
    line-height: 150%;
    color: rgba(var(--cl-b), 0.95);
    transition: all 0.3s;

    svg {
      transition: all 0.3s;
      color: rgba(var(--cl-c), 1);
      fill: rgba(var(--cl-c), 1);
    }
  }

  a.main-poster__text {
    &:hover {
      color: rgba(var(--cl-c), 1);
    }
  }
}
