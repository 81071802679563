.main-link{
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: rgba(var(--cl-c),1.0);
  display: flex;
  align-items: center;
  grid-gap: 8px;
  transition: all .3s;
  height: 46px;
  border-radius: 8px;
  position: relative;
  z-index: 1;
  padding: 0 14px;
  margin-right: 3px;

  &::before{
    position: absolute;
    content: '';
    z-index: -1;
    border-radius: inherit;
    inset: 0;
    transition: all .3s;
    opacity: 0;
    background: rgba(73, 69, 79, 0.08);
  }

  svg,img{
    width: 18px;
    height: 18px;
  }

  &:hover{
    &::before{
      opacity: 1;
    }
  }

  &:disabled{
    opacity: 0.6;
    pointer-events: none;
  }
}

.logo{
  width: 101px;
  height: 36px;

  svg,img{
    width: 100%;
    height: 100%;
  }
}

h1{
  font-family: var(--font-family);
  font-weight: 600;
  font-size: clamp(28px, 2.8571vw, 36px);
  line-height: 122%;
  color: rgba(var(--cl-b),.95);
}

h2{
  font-family: var(--font-family);
  font-weight: 600;
  font-size: clamp(24px, 2.2222vw, 28px);
  line-height: 129%;
  color: rgba(var(--cl-b),.95);
}