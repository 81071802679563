.publications-card{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    position: relative;
    z-index: 1;
    padding-bottom: 12px;

    &__box{
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 8px;
    }

    &__image{
        border-radius: 12px;
        width: 100%;
        height: clamp(193px, 20.873vw, 263px);
        overflow: hidden;

        img,picture{
            width: 100%;
            height: 100%;
            transition: all .3s;
        }

        &:hover{
            img,picture{
                transform: scale(1.05);
            }
        }
    }

    &__labels{
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }

    &__label{
        font-family: var(--second-family);
        font-weight: 500;
        font-size: 14px;
        line-height: 143%;
        letter-spacing: 0.01em;
        text-align: center;
        color: rgba(var(--cl-b),.95);
        padding: 3px 10px;
        border-radius: 8px;
        width: fit-content;
        height: 26px;
        background-color: rgba(var(--bg-c),1.0);
    }

    &__title{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: clamp(16px, 1.4286vw, 18px);
        line-height: 144%;
        color: rgba(var(--cl-b),.95);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
    }

    &__date{
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 14px;
        line-height: 143%;
        color: rgba(var(--cl-b),.58);
    }
}