.convert-section{
    margin-bottom: clamp(50px, 5.7143vw, 72px);

    &__box {
      display: flex;
      gap: clamp(25px, 2.5397vw, 32px);
      flex-direction: column;
    }
  
    &__top {
      display: flex;
      gap: 4px;
      flex-direction: column;
  
      .main-top {
        gap: 1px;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        align-items: flex-start;
      }
    }
  
  
    &__image {
      width: clamp(380px, 40.5556vw, 511px);
      height: clamp(300px, 29.8413vw, 376px);
      border-radius: 12px;
      overflow: hidden;
  
      img,
      picture {
        width: 100%;
        height: 100%;
      }
    }
  
    &__inner {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: clamp(20px, 1.9048vw, 24px);
    }
  
    &__map {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 19px;
    }
}