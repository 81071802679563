.accordion{
    display: flex;
    flex-direction: column;
    grid-gap: 8px;

    &__item{
        display: flex;
        flex-direction: column;
    }

    &__box{
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: clamp(16px, 1.5873vw, 20px) clamp(35px, 5.0794vw, 64px);

        p{
            font-size: clamp(14px, 1.2698vw, 16px);
            color: rgba(var(--cl-b),.95);

          
        }

        @include mobile{
            padding: 20px 64px;
        }
    }

    &__title{
        display: inline-flex;
        align-items: center;
        gap: clamp(10px, 1.2698vw, 16px);
        font-family: var(--font-family);
        font-weight: 600;
        font-size: clamp(16px, 1.4286vw, 18px);
        line-height: 144%;
        color: rgba(var(--cl-b),.95);
        transition: all .3s;

        svg,img{
            flex-shrink: 0;
            margin-top: calc(clamp(2px, .2381vw, 3px) * -1);
            display: flex;
            height: 20px;
            width: 20px;
        }
    }

    &__btn{
        width: 100%;
        display: flex;
        gap: 10px;
        align-items: center;
        transition: all .3s;
        text-align: start;
        cursor: pointer;
        padding: clamp(8px, 1.1905vw, 15px) clamp(12px, 2.2222vw, 28px) clamp(8px, 1.1905vw, 15px) clamp(18px, 1.9048vw, 24px);
        background: rgba(var(--bg-d),1.0);
        border-radius: 12px;
        border: solid 1px transparent;
        justify-content: space-between;
    
        &:hover{
           .accordion__title{
            color: rgba(var(--cl-c),1);
           }
        }

        &-icon{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            flex-shrink: 0;
            transition: all .3s;
            border-radius: 100%;
            border: solid 1px var(--br-f);
            position: relative;
            z-index: 1;

            &::before, &::after{
                position: absolute;
                content: '';
                z-index: 2;
                background: rgba(var(--bg-h),1.0);
                top: 50%;
                left: 50%;
                transition: all .3s;
                transition-delay: .1s;
                transform: translate(-50%, -50%);
                pointer-events: none;
            }

            &::before{
                height: clamp(12px, 1.1111vw, 14px);
                width: 2px;
            }

            &::after{
                width: clamp(12px, 1.1111vw, 14px);
                height: 2px;
            }
        }

        &.active{

            .accordion__btn-icon{
                transform: rotateZ(-90deg);
                &::after{
                    transform: translate(-50%, -50%) rotateZ(-90deg);
                    transform-origin: center;
                }
            }
        }

        &:disabled{
            opacity: 0.6;
            pointer-events: none;
        }
    }
}