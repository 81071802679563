.main-form {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  position: relative;
  width: 100%;

  &__title {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: clamp(22px, 2.2222vw, 28px);
    line-height: 129%;
    color: rgba(var(--cl-b), 0.95);
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__label {
    position: relative;
    z-index: 1;
    width: 100%;
  }

  &__subtitle{
    font-family: var(--second-family);
    font-weight: 400;
    font-size: 12px;
    line-height: 133%;
    letter-spacing: 0.03em;
    color: rgba(var(--cl-c),1.0);
    left: 14px;
    top: 0;
    position: absolute;
    opacity: 0;
    pointer-events: none;
    padding: 0 4px;
    transform: translateY(-50%);
    z-index: 2;
    background: rgba(var(--bg-a),1.0);
  }

  &__input {
    border-radius: 4px;
    border: solid 1px var(--br-b);
    height: 46px;
    padding: 5px 16px;
    width: 100%;
    max-width: 297px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: rgba(var(--cl-b), 0.95);

    &::placeholder {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: rgba(var(--cl-d), 1);
    }
    
    &:focus{
      border-color: var(--br-c);
    }

    &.is-writing{
      border-color: var(--br-c);

      & + .main-form__subtitle{
        opacity: 1;
      }
    }

    @include mobile{
      max-width: 100%;
    }
  }

  &__area {
    border-radius: 4px;
    border: solid 1px var(--br-b);
    height: 46px;
    padding: 11px 16px;
    width: 100%;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: rgba(var(--cl-b), 0.95);
    min-height: 150px;

    &::placeholder {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: rgba(var(--cl-d), 1);
    }

     
    &:focus{
      border-color: var(--br-c);
    }

    &.is-writing{
      border-color: var(--br-c);

      & + .main-form__subtitle{
        opacity: 1;
      }
    }
  }

  .rating {
    &__star {
      width: 22px;
      height: 22px;
      cursor: pointer;
    }

    &__item {
      font-family: var(--font-family);
      font-size: 16px;
      line-height: 150%;
      color: rgba(var(--cl-b), 0.95);
      margin: 0 9px -3px 0;
    }
  }

  .main-btn {
    max-width: 297px;
    width: 100%;

    @include mobile{
      max-width: 100%;
    }
  }
}
