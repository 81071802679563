.posters-card{
    position: relative;
    width: 100%;
    height: clamp(400px, 34.9206vw, 440px);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 8px;
    border-radius: 12px;
    overflow: hidden;
    padding: clamp(16px, 1.5873vw, 20px);

    &::before{
        position: absolute;
        background: var(--gr-b);
        content: '';
        width: 100%;
        height: 132px;
        left: 0;
        pointer-events: none;
        bottom: 0;
        z-index: -1;
    }

    &__title{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: clamp(18px, 1.746vw, 22px);
        line-height: 127%;
        color: rgba(var(--cl-a),1.0);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
    }

    &__deskr{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: clamp(14px, 1.2698vw, 16px);
        line-height: 150%;
        color: rgba(var(--cl-a),.6);
    }

    &__bottom{
        display: flex;
        flex-wrap: wrap;
        gap: 8px 16px;
        align-items: center;
    }

    &__info{
        display: flex;
        align-items: center;
        gap: 4px;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 14px;
        line-height: 143%;
        color: rgba(var(--cl-a),.6);

        svg,img{
            position: relative;
            top: -1px;
            width: 20px;
            height: 20px;
            fill: rgba(var(--cl-c),1.0);
        }
    }

    &__image{
        position: absolute;
        inset: 0;
        z-index: -2;

        img,picture{
            transition: all .3s;
            width: 100%;
            height: 100%;
        }
    }

    &:hover{
        .posters-card__image{
            img,picture{
                transform: scale(1.02);
            }
        }
    }
}