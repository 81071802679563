.exchange-list{
    display: flex;
    flex-direction: column;

    &__item{
        display: grid;
        grid-template-columns: 1fr 100px repeat(4,1fr);
        grid-gap: 10px;
        height: 52px;

        &:nth-child(odd){
            background: rgba(var(--bg-k),1.0);
        }

        &--head{
            border-bottom: solid 1px rgba(var(--bg-j),1.0);
            background: transparent !important;
        }
    }

    &__btn{
        display: flex;
        align-items: center;
        gap: 4px;
        transition: all .3s;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: rgba(var(--cl-b),.58);
        padding: 14px 16px;

        svg{
            flex-shrink: 0;
            fill: rgba(var(--cl-b),.58);
        }

        &:hover{
            color: rgba(var(--cl-c),1);
            svg{
                fill: rgba(var(--cl-c),1);
            }
        }

        justify-content: flex-end;

        &:nth-child(2), &:nth-child(1){
            justify-content: flex-start;
        }
    }

    &__name{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.01em;
        color: rgba(var(--cl-b),.95);
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 0 16px;
        padding-left: 12px;

        svg,img{
            width: 18px;
            height: 18px;
        }
    }

    &__value{
        padding: 14px 16px;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: rgba(var(--cl-b),.95);
        text-align: end;

        &.green{
            color: rgba(var(--cl-k),1.0);
        }

        &.red{
            color: rgba(var(--cl-j),1.0);
        }

        &:nth-child(2){
           text-align: start;
        }
    }

    &--crypto{
        .exchange-list{
            &__item{
                grid-template-columns: 1fr 100px repeat(4,1fr) 195px;
            }
        }
    }

    &--mode{
        .exchange-list{
            &__item{
                border-bottom: solid 1px rgba(var(--bg-j),1.0);
                &:nth-child(odd){
                    background: transparent;
                }
            }
        }
    }

    &--valute{
        .exchange-list{
            &__item{
                grid-template-columns: repeat(6,1fr);
            }

            &__btn{
                &:nth-child(2){
                    justify-content: flex-end;
                }
            }

            &__value{
                &:nth-child(2){
                    text-align: end;
                }
            }
        }

        &.exchange-list--mode{
            .exchange-list{
                &__value{
                    font-weight: 600;
                }
                &__item{
                    border-bottom: none;
               
                    &:nth-child(odd){
                        background: rgba(var(--bg-k),1.0);
                    }
                    &--head{
                        border-bottom: solid 1px rgba(var(--bg-j),1.0);
                    }
                }
            }
        }
    }

    &--bank{
        .exchange-list{
            &__item{
                grid-template-columns: repeat(5, 1fr);
            }

            &__btn{
                &:nth-child(2){
                    justify-content: flex-end;
                }
            }

            &__value{
                &:nth-child(2){
                    text-align: end;
                }
            }
        }
    }
}