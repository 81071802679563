.categories-card{
    display: flex;
    flex-direction: column;
    gap: clamp(8px, 1.1111vw, 14px);
    padding: 6px 6px 0;

    &__btn{
        display: flex;
        align-items: center;
        gap: clamp(8px, .9524vw, 12px);
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 16px;
        text-align: start;
        line-height: 150%;
        letter-spacing: 0.01em;
        color: rgba(var(--cl-b),.95);

        @include mobile{
            margin-left: -6px;
            width: calc(100% + 6px);
        }
    }

    &__icon{
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--br-d);
        border-radius: 6px;
        width: 40px;
        height: 40px;
        background: rgba(var(--bg-c),1.0);
        transition: all .3s;
        flex-shrink: 0;

        svg,img{
            width:24px;
            height:24px;
        }
    }

    &__list{
        display: flex;
        flex-direction: column;
    }

    &__item{
        transition: height .4s ease;
        overflow: hidden;
    }

    &__link{
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: rgba(var(--cl-b),1.0);
        transition: all .3s;
        padding-bottom: 8px;

        &:hover{
            color: rgba(var(--cl-c),1.0);
        }
    }

    &__open{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.01em;
        color: rgba(var(--cl-c),1.0);
        padding-right: 22px;
        position: relative;
        z-index: 1;
        width: fit-content;
        transition: all .3s;
        margin-top: calc(clamp(9px, 1.1111vw, 14px) * -1);

        &::before, &::after{
            position: absolute;
            content: '';
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);
            background: rgba(var(--cl-c),1.0);
            transition: all .3s;
        }

        &::before{
            width: 12px;
            height: 2px;
            right: 4px;
        }

        &::after{
            width: 2px;
            height:12px;
            right: 9px;
        }

        &.active{
            &::after{
                opacity: 0;
            }
        }

        &:hover{
            color: rgba(var(--cl-b),1.0);

            &::before,&::after{
                background: rgba(var(--cl-b),1.0);
            }
        }
    }
}