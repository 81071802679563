.weather-nav{
    display: flex;
    align-items: center;
    gap: clamp(24px, 2.5vw, 40px);
    padding: 12px 16px;

    &__btn{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.01em;
        color: rgba(var(--cl-b),.95);
        transition: all .3s;

        &:hover{
            color: rgba(var(--cl-b),.58);
        }

        &.active{
            color:  rgba(var(--cl-c),1.0);
        }
    }
}