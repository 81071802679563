.header {
  left: 0;
  right: 0;
  position: absolute;
  z-index: 10;
  top: 0;
  padding: 12px 0 16px;
  background: rgba(var(--bg-a), 1);

  @include tablet{
  &:after {
      position: absolute;
      content: "";
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);
      border-top: solid 1px var(--br-e);
      pointer-events: none;
      z-index: -1;
      bottom: 0;
    }
  }

  &.open-menu {
    transition: background 0.2s;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    background: rgba(var(--bg-a), 1);
  }

  @include tablet {
    padding: 15px 0;
  }

  &__box {
    display: grid;
    grid-template-columns: auto auto 1fr;
    position: relative;
    z-index: 2;
    align-items: center;
    gap: 12px;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: clamp(20px, 3.8095vw, 48px);
  }

  &__wrapp {
    display: grid;
    grid-template-columns: 1fr repeat(2, auto);
    align-items: center;
    grid-gap: 12px;
  }

  &-link {
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    transition: all 0.3s;
    color: rgba(var(--cl-b), 0.95);

    &__icon {
      color: rgba(var(--cl-d),1.0);
      width: 24px;
      height: 24px;
      display: flex;
      position: relative;
      z-index: 1;

      svg,
      img {
        transition: all 0.3s;
        width: 100%;
        height: 100%;
      }
    }

    &__count {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      background: rgba(var(--bg-g), 1);
      font-family: var(--second-family);
      font-weight: 500;
      font-size: 11px;
      border-radius: 100%;
      flex-shrink: 0;
      line-height: 100%;
      letter-spacing: 0.05em;
      text-align: center;
      color: rgba(var(--cl-a), 1);
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      transform: translate(35%, -30%);
    }

    &:hover {
      color: rgba(var(--cl-c), 1);

      .header-link__icon{
        color: rgba(var(--cl-c), 1);
      }
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    z-index: 5;
    width: 100%;
    grid-column: 1/-1;
    position: relative;

    &::before,
    &:after {
      position: absolute;
      content: "";
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);
      border-top: solid 1px var(--br-e);
      pointer-events: none;
      z-index: -1;
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }

    ul {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      grid-gap: clamp(5px, 0.7937vw, 10px);

      @include for-tablet {
        height: 100%;
      }

      li {
        display: flex;
        align-items: center;
      }

      a,
      .menu-button {
        text-align: center;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        grid-gap: 4px;
        position: relative;
        z-index: 1;
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.01em;
        color: rgba(var(--cl-b), 0.95);
        padding: 12px 12px 14px;

        @include for-tablet {
          height: 50px;
          flex-shrink: 0;
        }

        &:before {
          z-index: 2;
          position: absolute;
          width: 100%;
          height: 1px;
          content: "";
          background: rgba(var(--bg-f), 1);
          bottom: 0;
          left: 0;
          opacity: 0;
          visibility: hidden;
          transition: all 0.4s;
          pointer-events: none;
        }

        &.active {
          &:before {
            opacity: 1;
            visibility: visible;
          }
        }

        &:hover {
          color: rgba(var(--cl-c), 1);
        }
      }
    }
  }

  @include for-tablet {
    .sub-menu[data-content] {
      max-height: initial !important;
      visibility: hidden;
    }
  }

  @include tablet {

    .logo{
      width: 85px;
      height: 30px;
    }

    .sub-menu[data-content] {
      padding: 0 16px;
      margin-top: 0;
      border: transparent;
      grid-gap: 20px;

      a {
        padding: 0;
        font-size: 20px;
      }

      li {
        &:first-of-type {
          padding-top: 10px;
        }
      }
    }
  }

  .menu-button {
    @include tablet {
      &.active {
        svg {
          transform: scaleY(-1);
        }
      }
    }

    svg {
      transition: all 0.3s;
      fill: transparent;
      width: 24px;
      height: 24px;
    }
  }

  .sub-menu {
    background: rgba(var(--bg-a),1.0);
    border: solid 1px var(--br-b);
    flex-direction: column;
    align-items: start;
    gap: 10px;
    white-space: nowrap;
    opacity: 0;
    transition: 0.2s ease-in-out;
    z-index: 10;

    @include for-tablet {
      left: -5px;
      position: absolute;
      top: 100%;
      padding: 15px 10px 18px;
      width: calc(100% + 10px);
    }

    li {
      @include for-tablet {
        width: 100%;
        display: flex;
      }
    }

    a {
      text-align: start;
      padding: 0;
      font-size: clamp(13px, 1.2799vw, 15px);

      @include for-tablet {
        width: 100%;
        height: fit-content;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .burger {
    justify-self: end;
    @include for-tablet {
      display: none;
    }

    @include tablet {
      @include burger;
    }

    @include mobile{
      margin-right: 11px;
    }
  }

  .lang-box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 10;
    transition: all 0.3s;

    .menu-button {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      &::before {
        position: absolute;
        height: 40px;
        top: 50%;
        left: 50%;
        width: 100%;
        opacity: 0;
        visibility: hidden;
        z-index: 2;
        transform: translateX(-55%);

        @include for-tablet {
          content: "";
        }
      }

      svg {
        fill:  #1A1918;
        width: 24px;
        height: 24px;
      }
    }

    .sub-menu {
      max-height: initial !important;
      visibility: hidden;
      position: absolute;
      display: flex;
      flex-direction: column;
      padding: 11px 5px;
      border-radius: 8px;
      top: calc(100% + 5px);
      gap: 0;

      li {
        &:last-of-type {
          a {
            margin: 0;
          }
        }

        &:first-of-type {
          padding: 0;
        }
      }

      a {
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        grid-gap: 8px;
        margin-bottom: 8px;
        transition: all 0.3s;
        color: rgba(var(--cl-b),1.0);

        &.disabled {
          display: none;
        }

        &:hover {
          color: rgba(var(--cl-c),1.0);
        }
      }

      @include tablet {
        border: 1px solid rgba(62, 62, 77, 0.6);
      }
    }

    &:hover {
      .menu-button {
        background: rgba(73, 69, 79, 0.08);

        &::before {
          visibility: visible;
        }
      }

      .sub-menu {
        opacity: 1;
        visibility: visible !important;
      }
    }

    @include tablet {
      background: rgba(42, 42, 61, 0.38);
    }
  }

  .city-select{
    z-index: 90;
  }
}
