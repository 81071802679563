.weather-cities{
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__title{
        color: rgba(var(--cl-b),.95);
        font-family: var(--font-family);
        font-weight: 600;
        font-size: clamp(18px, 1.746vw, 22px);
        line-height: 127%;
    }

    &__list{
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    &__item{
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__name{
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: rgba(var(--cl-b),.58);
        flex-grow: 1;
    }

    &__value{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.01em;
        color: rgba(var(--cl-b),.95);

        i{
            font-style: normal;
        }
    }

    &__icon{
        display: flex;
        width: 24px;
        height: 24px;

        svg,img{
            width: 100%;
            height: 100%;
        }
    }
}