:root {
    --cta-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='370' height='279' fill='none'%3E%3Cg filter='url(%23a)'%3E%3Cellipse cx='409.5' cy='140' fill='%2357E06C' rx='169.5' ry='227'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='a' width='819' height='934' x='0' y='-327' color-interpolation-filters='sRGB' filterUnits='userSpaceOnUse'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur result='effect1_foregroundBlur_6_12479' stdDeviation='120'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");

    --cta-bg2: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='369' height='279' fill='none'%3E%3Cg filter='url(%23a)'%3E%3Cellipse cx='-40.5' cy='140' fill='%2357E06C' rx='169.5' ry='227'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='a' width='819' height='934' x='-450' y='-327' color-interpolation-filters='sRGB' filterUnits='userSpaceOnUse'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur result='effect1_foregroundBlur_6_12478' stdDeviation='120'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");

    --offer-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1172' height='263' fill='none'%3E%3Cg filter='url(%23a)'%3E%3Cellipse cx='586' cy='314.5' fill='%2357E06C' rx='698.5' ry='149.5'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='a' width='1727' height='629' x='-277.5' y='0' color-interpolation-filters='sRGB' filterUnits='userSpaceOnUse'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur result='effect1_foregroundBlur_6_12054' stdDeviation='82.5'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");

    --about-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1169' height='198' fill='none'%3E%3Cg filter='url(%23a)'%3E%3Cellipse cx='584' cy='207' fill='%2357E06C' rx='99' ry='547' transform='rotate(-90 584 207)'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='a' width='1334' height='438' x='-83' y='-12' color-interpolation-filters='sRGB' filterUnits='userSpaceOnUse'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur result='effect1_foregroundBlur_65_22583' stdDeviation='60'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");

    --hero-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1512' height='435' fill='none'%3E%3Cg filter='url(%23a)'%3E%3Cellipse cx='756' cy='-55' fill='%2357E06C' rx='210' ry='662' transform='rotate(-90 756 -55)'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='a' width='1884' height='980' x='-186' y='-545' color-interpolation-filters='sRGB' filterUnits='userSpaceOnUse'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur result='effect1_foregroundBlur_70_19191' stdDeviation='140'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");

    --slot-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1170' height='190' fill='none'%3E%3Cg filter='url(%23a)'%3E%3Cellipse cx='584.5' cy='-29' fill='%2357E06C' rx='99' ry='547' transform='rotate(-90 584.5 -29)'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='a' width='1334' height='438' x='-82.5' y='-248' color-interpolation-filters='sRGB' filterUnits='userSpaceOnUse'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur result='effect1_foregroundBlur_77_15133' stdDeviation='60'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");

    --check: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='26' fill='none'%3E%3Cpath fill='%2357E06C' fill-rule='evenodd' d='M13 .167C6.074.167.458 5.782.458 12.708.458 19.635 6.074 25.25 13 25.25c6.927 0 12.542-5.615 12.542-12.542C25.542 5.782 19.927.167 13 .167Z' clip-rule='evenodd'/%3E%3Cpath fill='%23000004' fill-rule='evenodd' d='M20.619 7.715a.875.875 0 0 1 0 1.237l-9.333 9.333a.875.875 0 0 1-1.238 0l-4.083-4.083a.875.875 0 1 1 1.237-1.237l3.465 3.464 8.714-8.714a.875.875 0 0 1 1.238 0Z' clip-rule='evenodd'/%3E%3C/svg%3E");
     
    --cons: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cpath fill='%23EE4D4D' fill-rule='evenodd' d='M16 1.667C8.084 1.667 1.666 8.084 1.666 16S8.084 30.333 16 30.333c7.916 0 14.333-6.417 14.333-14.333S23.916 1.667 16 1.667Z' clip-rule='evenodd'/%3E%3Cpath fill='%23F9F9F9' fill-rule='evenodd' d='M9.636 9.636a1 1 0 0 1 1.414 0L22.364 20.95a1 1 0 1 1-1.415 1.414L9.636 11.05a1 1 0 0 1 0-1.414Z' clip-rule='evenodd'/%3E%3Cpath fill='%23F9F9F9' fill-rule='evenodd' d='M9.636 22.364a1 1 0 0 1 0-1.414L20.949 9.636a1 1 0 1 1 1.415 1.414L11.05 22.364a1 1 0 0 1-1.414 0Z' clip-rule='evenodd'/%3E%3C/svg%3E");
          
}