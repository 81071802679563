.estate-card{
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__image{
        display: flex;
        width: 100%;
        height: 206px;
        border-radius: 8px;
        overflow: hidden;

        img,picture{
            transition: all .3s;
            width: 100%;
            height: 100%;
        }
    }

    &__box{
        display: flex;
        flex-direction: column;
    }

    &__value{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.01em;
        color: rgba(var(--cl-c),1.0);
    
        i{
            font-style: normal;
        }
    }

    &__title{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.01em;
        color: rgba(var(--cl-b),.95);
    }

    &__city{
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 14px;
        line-height: 143%;
        color: rgba(var(--cl-b),.58);
    }

    &:hover{
        .estate-card__image{
            img,picture{
                transform: scale(1.02);
            }
        }
    }
}