.modal {
  position: relative;
  flex-direction: column;
  align-items: center;
  grid-gap: 24px;
  border-radius: 16px;
  z-index: 1;
  max-width: clamp(1000px, 100vw, 1260px);
  width: 91vw;
  background-color: rgba(var(--bg-a),1.0);
  overflow: hidden;
  max-height: 750px;

  &__box {
    display: grid;
    grid-template-columns: 297px 1fr;
    width: 100%;
  }

  &__map{
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img,picture, iframe{
      width: 100%;
      height: 100%;
    }
  }

}
