.main-exchange{
    display: flex;
    flex-direction: column;
    gap: clamp(24px, 2.5397vw, 32px);

    &__top{
        display: flex;
        gap: 24px;
        align-items: center;

        & > .main-btn{
            margin-left: auto;
        }
    }

    &__btns{
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 16px;
        align-self: flex-end;
    }

    & > .main-btn--transparent{
        align-self: flex-end;
    }

    &__inner{
        display: flex;
        flex-direction: column;
    }

    &__text{
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: rgba(var(--cl-b),.58);
    }

    &__title{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: clamp(22px, 2.2222vw, 28px);
        line-height: 129%;
        color: rgba(var(--cl-b),.95);
    }
}