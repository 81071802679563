.news-section{
    margin-bottom: clamp(50px, 5.7143vw, 72px);

    &__box {
        display: flex;
        flex-direction: column;
        gap:  clamp(24px, 2.5397vw, 32px);
    }

    &__inner {
        display: grid;
        grid-template-columns: 1fr clamp(320px, 32.0635vw, 404px);
        grid-gap: 24px clamp(25px, 3.8095vw, 48px);
      }

      &__content{
        display: flex;
        flex-direction: column;
        gap:  clamp(30px, 3.1746vw, 40px);
      }

      &__aside{
        display: flex;
        flex-direction: column;
        gap: 24px;

        .info-list{
          &::before{
            margin-bottom: 8px;
            font-size: clamp(20px, 1.9048vw, 24px);
            line-height: 133%;
          }
        }
      }

      &__canals{
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 20px;
        border: 1px solid var(--br-e);
        border-radius: 12px;

        &::before{
            content: attr(data-title);
            display: inline-block;
            font-family: var(--font-family);
            font-weight: 600;
            font-size: clamp(18px, 1.746vw, 22px);
            line-height: 127%;
            color: rgba(var(--cl-b),.95);
            padding-bottom: 16px;
            border-bottom: 1px solid var(--br-e);
        }
      }

      &__canal{
        display: flex;
        align-items: center;
        gap: 12px;
        padding-bottom: 16px;
        border-bottom: 1px solid var(--br-e);
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.01em;
        color: rgba(var(--cl-b),.95);
        transition: all .3s;

        svg,img{
            width: 37px;
            height: 40px;
        }

        &:last-of-type{
            padding-bottom: 0;
            border: none;
        }

        &:hover{
            color: rgba(var(--cl-c),1.0);
        }
      }
}