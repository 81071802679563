.review-section {
  padding-top: 5px;
  margin-bottom: clamp(48px, 5.7143vw, 72px);

  @include tablet{
    padding-top: 24px;
  }

  &__box {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 832px;

    @include small-tablet{
      flex-direction: column-reverse;
      gap: 48px;
    }
  }

  .main-review{
    @include for-small-tablet{
      padding-bottom: 20px;
      border-bottom: solid 1px var(--br-a);
    }
  }

}
