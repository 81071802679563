.publications-slider {
  @include for-small-tablet{
    width: 100%;
  }

  @include small-tablet {
    width: calc(100% + (var(--container-offset) * 2));
  }

  .swiper {
    &-container {
      width: 100%;

      @include small-tablet{
        padding:0 var(--container-offset);
      }
    }

    &-wrapper {
      @include for-small-tablet {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: clamp(18px, 1.9048vw, 24px);
      }
    }
  }

  &.estate{
    margin-top: -5px;
    @include tablet {
      width: calc(100% + (var(--container-offset) * 2));
    }

    .swiper{
      &-container {
        @include tablet{
          padding:0 var(--container-offset);
        }
      }

      &-wrapper{
        @include for-small-tablet{
          grid-template-columns: repeat(4, 1fr);
          grid-gap: 8px;
        }
      }
    }
  }
}
