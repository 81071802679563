.exchange-section{
    margin-bottom: clamp(50px, 5.7143vw, 72px);

    &__box{
        display: flex;
        gap: 4px;
        flex-direction: column;
    }

    &__inner {
      display: flex;
      gap: clamp(50px, 5.7143vw, 72px);
      flex-direction: column;
    }
}