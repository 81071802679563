.info-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  height: fit-content;

  &::before{
    content: attr(data-title);
    font-family: var(--font-family);
    font-weight: 600;
    font-size: clamp(24px, 2.2222vw, 28px);
    line-height: 129%;
    color: rgba(var(--cl-b),.95);
    display: inline-block;
    width: 100%;
    margin-bottom: 24px;
  }

  &__link {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-family: var(--second-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.01em;
    text-align: center;
    color: rgba(var(--cl-e), 1);
    background: rgba(var(--bg-c), 1);
    border-radius: 8px;
    transition: all 0.3s;

    &:hover {
      background: rgba(var(--bg-e), 1);
    }

    i{
      color: rgba(var(--cl-c), 1);
      font-style: normal;
    }
  }
}
