.categories-list{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: clamp(20px, 1.9048vw, 24px) 24px;

    @include small-tablet{
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px 16px;
    }

    &__text{
        transition: all .3s;
        @include mobile{
            hyphens: auto;
            hyphenate-character: '';
        }
        @include ios{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
        }
    }

    &__link{
        display: flex;
        align-items: center;
        gap: clamp(8px, .9524vw, 12px);
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.01em;
        color: rgba(var(--cl-b),.95);

       

        &:hover{
            color: rgba(var(--cl-c),1);
        }
    }

    &__icon{
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--br-d);
        border-radius: 6px;
        width: 40px;
        height: 40px;
        background: rgba(var(--bg-c),1.0);
        transition: all .3s;

        svg,img{
            width: 24px;
            height: 24px;
        }
    }

    &--mode{
        @include mobile{
            margin-top: -6px;
            grid-gap: 26px;
            grid-template-columns: 100%;
        }
    }
}