.convert-form{
    border-radius: 16px;
    padding: 16px 20px;
    height: fit-content;
    background: rgba(var(--bg-k),1.0);
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-gap: 24px clamp(16px, 1.9048vw, 24px);
    align-items: center;

    &__btn{
        transition: all .3s;
        width: 24px;
        height: 24px;
        display: flex;

        svg{
            transition: all .3s;
        }

       &:hover{
        svg{
            color: rgba(var(--cl-c),1.0);
           }
       }
    }

    &__label{
        display: flex;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        background: rgba(var(--bg-a),1.0);
        border: solid 1px var(--br-b);
        height: 58px;
        position: relative;
        z-index: 25;
    }

    &__field{
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__text{
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 14px;
        line-height: 143%;
        color: rgba(var(--cl-b),.58);
    }

    &__input{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 24px;
        line-height: 100%;
        color: rgba(var(--cl-f),1.0);
        text-indent: 16px;
    }
}