.categories-section{
    margin-bottom: clamp(48px, 5.7143vw, 72px);
    
    &__box{
        display: flex;
        flex-direction: column;
        gap: clamp(25px, 2.5397vw, 32px);

        @include mobile{
            gap: 4px;
        }
    }

    &__top{
        display: flex;
        flex-direction: column;
        gap: 8px;

        @include mobile{
            display: contents;
        }

        .main-top{
            @include mobile{
                display: contents;
            }

            h1,h2,h3,h4,h5,h6{
                @include mobile{
                    order: -2;
                    margin-bottom: 20px;
                }
            }

            .main-link{
                @include mobile{
                    align-self: center;
                }
            }
        }
    }

    .categories-list{
        @include mobile{
            order: -1;
            margin-bottom: 24px;
        }
    }

    .breadcrumbs{
        @include mobile{
            order: -3;
        }
    }


    &--advertisements{
        .categories-section{
            &__box{
                border-bottom: solid 1px var(--br-a);
                padding-bottom:clamp(48px, 5.7143vw, 72px);
            }
        }
    }
}