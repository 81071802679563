.search-form{
    width: 100%;
    position: relative;
    z-index: 1;
    display: grid;
    grid-template-columns: 1fr 112px;
    grid-gap: 12px;
    border-radius: 12px;
    padding: clamp(20px, 3.1746vw, 40px);

    @include mobile{
        grid-template-columns: 100%;
    }

    &__bg{
        position: absolute;
        z-index: -1;
        border-radius: 12px;
        overflow: hidden;
        inset: 0;
        pointer-events: none;
        background: rgba(var(--bg-e),1.0);

        &::before{
            position: absolute;
            content: '';
            inset: 0;
            z-index: 1;
            pointer-events: none;
            border-radius: inherit;
            background: rgba(38, 38, 38, 0.2);
        }

        img,picture{
            width: 100%;
            height: 100%;
        }
    }

    &__top{
        grid-column: 1/-1;
        display: flex;
        align-items: center;
        gap: 8px;

        &::before{
            white-space: nowrap;
            margin-right: 8px;
            content: attr(data-title);
            display: inline-block;
            font-family: var(--font-family);
            font-weight: 600;
            font-size: clamp(16px, 1.4286vw, 18px);
            line-height: 144%;
            color: rgba(var(--cl-a),1.0);
        }

        @include small-tablet{
            overflow: auto;
            width: calc(100% + (var(--container-offset) * 2));
            margin-left: calc(var(--container-offset) * -1);
            padding: 0 var(--container-offset);
        }
    }

    &__field{
        display: flex;
        align-items: center;
        background: rgba(var(--bg-a),1.0);
        border-radius: 4px;
        border: solid 1px var(--br-b);
        height: 46px;

        .search-form{
            &__label{
                border-left: solid 1px var(--br-b);
            }

            &__input{
                border: none;
                background: transparent;
                border-radius: 0;
            }
        }
    }

    &__label{
        width: 100%;
    }

    &__input{
        display: flex;
        align-items: center;
        padding: 10px 16px;
        background: rgba(var(--bg-a),1.0);
        border: 1px solid var(--br-b);
        border-radius: 4px;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: rgba(var(--cl-b),1.0);
        transition: all .3s;

        &::placeholder{
            font-family: var(--font-family);
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: rgba(var(--cl-d),1.0);
        }

        &:focus{
            border-color: var(--br-c);
        }
    }

    .main-btn{
        svg,img{
            position: relative;
            top: -1px;
        }

        svg{
            fill: rgba(var(--cl-a),1.0);
        }

        @include mobile{
            width: 100%;
        }
    }

    &--mode{
        padding: 0;
        border-radius: 0;
        grid-template-columns: 100%;
        max-width: 618px;

        .search-form{

            &__label{
                position: relative;
                z-index: 1;
            }

            &__input{
                padding-left: 48px;
            }

            &__btn{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 4px;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;

                svg{
                    transition: all .3s;
                    width: 24px;
                    height: 24px;
                    fill: rgba(var(--cl-h),1.0);
                }

                &:hover{
                    svg{
                        fill: rgba(var(--cl-c),1.0);
                    }
                }
            }
        }
    }
}