.publications-section {
  margin-bottom: clamp(48px, 5.7143vw, 72px);

  &__box {
    display: flex;
    gap: clamp(24px, 2.9365vw, 37px);
    flex-direction: column;
    align-items: center;
  }

  .main-top {
    @include mobile {
      display: contents;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @include mobile {
        order: -2;
        align-self: flex-start;
      }
    }

    .main-link {
      @include mobile {
        margin-top: 8px;
        align-self: center;
      }
    }
  }

  .publications-slider{
    @include mobile{
      order: -1;
    }
  }
}
