.object-section {
  margin-bottom: clamp(56px, 5.7143vw, 72px);

  &__box {
    display: flex;
    gap: 16px;
    flex-direction: column;
  }

  &__top {
    display: flex;
    gap: 4px;
    flex-direction: column;

    .main-top {
      gap: 1px;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      align-items: flex-start;
    }

    @include mobile{
      .rating{

        &__star{
          width: 18px;
          height: 18px;
        }

        &__item{
          margin-bottom: -3px;
          margin-left: 4px;
          font-size: 14px;
          line-height: 143%;
        }
      }
    }
  }

  &__contact {
    display: flex;
    align-items: center;
    gap: 8px 20px;
    flex-wrap: wrap;

    .object-section__link {
      svg {
        fill: rgba(var(--cl-c), 1);
        color: rgba(var(--cl-c), 1);
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: clamp(24px, 3.1746vw, 40px);
  }

  &__about {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px clamp(20px, 1.9048vw, 24px);

    .object-section {
      &__inner {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: clamp(20px, 1.9048vw, 24px);

        
        @include for-tablet{
          grid-column: 2/4;

          .object-section__subtitle {
            grid-column: 1/-1;
          }
        }

        @include tablet{
          grid-gap: 16px;
          grid-template-columns: 100%;
        }
      }

      &__subtitle {
        font-size: clamp(22px, 2.2222vw, 28px);
        line-height: 129%;
      }

      &__info {
        p {
          color: rgba(var(--cl-b), 0.95);
        }

        @include tablet{
          .object-section__list{
            margin-top: 0;
          }
        }
      }

      &__map {
        .object-section__image {
          width: 100%;
        
          @include for-tablet{
            height: clamp(250px, 22.5397vw, 284px);
          }
        }

        .main-btn {
          margin-top: 8px;

          @include for-tablet{
            width: 100%;
          }

          @include tablet{
            align-self: center;
          }

          @include mobile{
            width: 100%;
          }
        }
      }

      &__list {
        flex-grow: 1;
      }
    }

    .social {
      @include for-tablet{
        display: grid;
        padding: 20px;
        grid-template-columns: 1fr minmax(130px, auto);
      }
    }

    @include tablet{
      grid-template-columns: 100%;
    }
  }

  &__image {
    width: clamp(380px, 40.5556vw, 511px);
    height: clamp(300px, 28.4921vw, 359px);
    border-radius: 12px;
    overflow: hidden;

    img,
    picture {
      width: 100%;
      height: 100%;
    }

    @include tablet{
      width: 100%;
      height: clamp(240px, 35.059vw, 359px);
    }
  }

  &__inner {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 24px clamp(20px, 3.1746vw, 40px);

    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }

    p {
      color: rgba(var(--cl-b), 0.95);
    }

    @include tablet{
      grid-template-columns: 100%;
    }
  }

  &__subtitle {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 18px;
    line-height: 144%;
    color: rgba(var(--cl-b), 0.95);
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .object-section__text {
      display: flex;
      align-items: center;
      gap: clamp(8px, 0.9524vw, 12px);
      color: rgba(var(--cl-b), 0.95);

      svg {
        width: 20px;
        height: 20px;
        margin-left: 3px;
        margin-right: 1px;
        fill: rgba(var(--cl-g), 1);
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    gap: clamp(8px, 0.9524vw, 12px);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: clamp(15px, 1.2698vw, 16px);
    line-height: 150%;
    color: rgba(var(--cl-b), 0.95);
    transition: all 0.3s;

    svg {
      flex-shrink: 0;
      transition: all 0.3s;
      color: rgba(var(--cl-g), 1);
      fill: rgba(var(--cl-g), 1);
    }

    &:hover {
      color: rgba(var(--cl-c), 1);

      svg {
        color: rgba(var(--cl-c), 1);
        fill: rgba(var(--cl-c), 1);
      }
    }

    &.orange{
      @include mobile{
        color: rgba(var(--cl-c),1.0);
        font-weight: 600;
      }
    }

    @include mobile{
      font-size: 16px;
      gap: 12px;
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: 12px 24px;

    @include mobile{
      flex-direction: column;

      .main-btn{
        width: 100%;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .social {
      margin-top: 12px;
    }

    .object-section__list {
      margin-top: clamp(4px, .6349vw, 8px);
    }

    .object-section__btns {
      margin-top: 12px;
    }
  }

  &__map {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .object-section__image {
      @include for-tablet{
        height: 300px;
      }
    }

    @include mobile{
      order: 2;
    }
  }

  &__text {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: rgba(var(--cl-b), 0.38);
  }
}
