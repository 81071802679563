.faq-section {
  margin-bottom: clamp(49px, 5.7143vw, 72px);

  &__box {
    display: flex;
    gap: clamp(24px, 2.5397vw, 32px);
    flex-direction: column;
    align-items: center;    
  }
}
