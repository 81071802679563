.object-slider {
  display: flex;
  grid-gap: 8px;

  @include for-mobile {
    height: clamp(300px,40.3968vw, 509px);
  }

  @include mobile {
    flex-direction: column;
  }

  &__main {
    width: clamp(700px, 76.3492vw, 962px);

    @include for-mobile{
      border-radius: 12px 0 0 12px;
    }
  
    @include mobile {
      width: 100%;
      height: clamp(240px, 52.083vw, 300px);
      display: flex;
      border-radius: 12px 12px 0 0;
    }

    .swiper {
      &-slide {
        display: flex;
        width: 100%;
        aspect-ratio: 1;
        z-index: 1;
        position: relative;

        overflow: hidden;

        a {
          display: flex;
          width: 100%;
          aspect-ratio: 1;
        }

        img,
        picture {
          display: flex;
          width: 100%;
          aspect-ratio: 1;
          transition: all 0.3s;
        }

        &:hover {
          img,
          picture {
            transform: scale(1.01);
          }
        }
      }

      &-btn {
        border-radius: 100%;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 5;
        background: transparent;
        border: solid 1px var(--br-f);
        transition: all 0.3s;
        position: absolute;
        top: 50%;

        svg,
        img {
          color: #D9D5D4;
          width: 24px;
          height: 24px;
          left: -1px;
          position: relative;
        }

        &:hover {
          background: rgba(var(--bg-e), 1);

          svg,
          img {
            color: rgba(var(--cl-f),1.0);
            width: 24px;
            height: 24px;
          }
        }

        &.prev {
          left: 12px;
          transform: translate(0%, -50%);

          svg,
          img {
            transform: scaleX(-1);
          }
        }

        &.next {
          right: 12px;
          transform: translate(0%, -50%);

          svg,img{
            left: 1px;
          }
        }

        &.swiper-button-lock {
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
        }
      }
    }
  }

  .sub-slider {
    @include for-mobile {
      border-radius: 0 12px 12px 0;
      width: 100%;
      max-width: clamp(200px, 23.0159vw, 290px);
    }

    @include mobile {
      border-radius: 0 0 12px 12px;
      width: 100%;
      height: clamp(70px, 22.569vw, 130px);
    }

    @include ios{
      height: 70px;
    }

    .swiper-slide {
      display: flex;
      width: 100%;
      aspect-ratio: 1;
      cursor: pointer;

      img,
      picture {
        display: flex;
        width: 100%;
        aspect-ratio: 1;
      }
    }
  }
}
