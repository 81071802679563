.weather-list{
    display: flex;
    flex-direction: column;
    gap: clamp(20px, 2.2222vw, 28px);
    max-width: 939px;

    &__item{
        display: flex;
        flex-direction: column;
        gap: 8px;

        &::before{
            content: attr(data-title);
            display: inline-block;
            font-family: var(--font-family);
            font-weight: 600;
            font-size: 18px;
            line-height: 144%;
            color: rgba(var(--cl-b),.95);
        }

        ul:not([class]){
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 8px 24px;

            li{
                &::before{
                    content: none;
                }

                a{
                    font-family: var(--font-family);
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                    color: rgba(var(--cl-b),.95);
                    transition: all .3s;

                    &:hover{
                        color: rgba(var(--cl-c),1.0);
                    }
                }
            }
        }
    }
}