.main-price{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px 50px;
    padding: 20px;

    .main-top{
        padding-bottom: 16px;
        grid-column: 1/-1;
        border-bottom: solid 1px var(--br-a);

        .main-link{
            margin-right: -13px;
        }
    }

    &__list{
        display: flex;
        flex-direction: column;
        gap: 12px;

        &:first-of-type{
            position: relative;
            z-index: 1;

            &::before{
                position: absolute;
                content: '';
                width: 1px;
                height: 100%;
                right: -25px;
                transform: translateX(50%);
                top: 0;
                background: var(--br-a);
                z-index: -1;
                pointer-events: none;
            }
        }
    }

    &__item{
        display: flex;
        align-items: center;
        gap: 5px;
        justify-content: space-between;
    }

    &__name{
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: rgba(var(--cl-b),.95);
    }

    &__value{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.01em;
        text-align: right;
        color: rgba(var(--cl-b),.95);

        i{
            font-style: normal;
        }
    }
}