.directory-section{
    margin-bottom: clamp(50px, 5.7143vw, 72px);
    overflow: hidden;

    &__top{
        display: flex;
        gap: 8px;
        flex-direction: column;

        @include mobile{
          gap: 4px;
        }
    }

    &__box {
      display: flex;
      gap: clamp(24px, 2.5397vw, 32px);
      flex-direction: column;
    }

    .popular-slider{
      align-self: center;
    }

    .main-top{
      @include mobile{
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
      }

      .directory-select{
        @include mobile{
          width: 100%;
        }

        .select{
          &__header{
            justify-content: center;
          }

          &__current{
            width: fit-content;
            flex-grow: initial;
          }

          &__option{
            text-align: center;
            justify-content: center;
          }
        }
      }
    }
}

.directory-section + .popular-section{
  padding-top: 8px;
}