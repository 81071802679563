.popular-card{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 20px;
    height: clamp(300px, 26.9841vw, 340px);
    width: 100%;
    position: relative;
    z-index: 1;
    border-radius: 12px;
    overflow: hidden;

    &__label{
        position: absolute;
        top: 16px;
        left: 16px;
        z-index: 2;
        display: flex;
        width: 37px;
        height: 50px;

        svg,img{
            width: 100%;
            height: 100%;
        }
    }

    &::before{
        position: absolute;
        content: '';
        width: 100%;
        left: 0;
        bottom: 0;
        z-index: -1;
        background: var(--gr-a);
        pointer-events: none;
        width: 100%;
        height: 130px;
    }

    &__image{
        position: absolute;
        z-index: -2;
        inset: 0;
        border-radius: inherit;

        img,picture{
            transition: all .3s;
            width: 100%;
            height: 100%;
        }
    }
   
    &__box{
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 16px;
    }

    &__title{
        max-width: 260px;
        width: 100%;
        font-family: var(--font-family);
        font-weight: 600;
        font-size: clamp(16px, 1.4286vw, 18px);
        line-height: 144%;
        color: rgba(var(--cl-a),1.0);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
    }

    &:hover{
        .popular-card__image{
            img,picture{
                transform: scale(1.02);
            }
        }
    }
}