.weather-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
   

    &__time{
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: rgba(var(--cl-b),.95);
    }

    &__icon{
        display: flex;
        width: clamp(35px, 3.1746vw, 40px);
        height: clamp(35px, 3.1746vw, 40px);
        svg,img{
            width: 100%;
            height: 100%;
        }
    }
}