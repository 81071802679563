// --------------- hex to rgba mixin ---------------
@function HexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}
:root {
// --------------- default global variable ---------------
  --body-bg: #fff;
  --body-font-size: 16px;
  --content-width: 1260px;
  --container-offset: clamp(16px, 1.9048vw, 24px);
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // --------------- default JS variable ---------------
    --header-height: 136px;

    @include tablet{
      --header-height: 66px;
    }

// --------------- default scroll variable ---------------
  --scrollbar-thumb: #ed9e00;
  --scrollbar-track:  #d8d8d8;
  --scrollbar-track-hover: #d8d8d8;
  --overlay-bg: rgba(0, 0, 0, 0.5);

// --------------- default font-family variable ---------------
--font-family: "Proxima", sans-serif; // var(--font-family);
--second-family: "Inter", sans-serif; // var(--second-family);

// --------------- default text color variable ---------------
  --cl-a: #{HexToRGB(#ffffff)}; // rgba(var(--cl-a),1.0);
  --cl-b: #{HexToRGB(#000000)}; // rgba(var(--cl-b),1.0);
  --cl-c: #{HexToRGB(#F1582E)}; // rgba(var(--cl-c),1.0);
  --cl-d: #{HexToRGB(#8E8B8A)}; // rgba(var(--cl-d),1.0);
  --cl-e: #{HexToRGB(#332F2E)}; // rgba(var(--cl-e),1.0);
  --cl-f: #{HexToRGB(#1A1918)}; // rgba(var(--cl-f),1.0);
  --cl-g: #{HexToRGB(#8D9099)}; // rgba(var(--cl-g),1.0);
  --cl-h: #{HexToRGB(#4D4A49)}; // rgba(var(--cl-h),1.0);
  --cl-j: #{HexToRGB(#C71212)}; // rgba(var(--cl-j),1.0);
  --cl-k: #{HexToRGB(#008C39)}; // rgba(var(--cl-k),1.0);

// --------------- default background color variable ---------------
  --bg-a: #{HexToRGB(#ffffff)}; // rgba(var(--bg-a),1.0);
  --bg-b: #{HexToRGB(#000000)}; // rgba(var(--bg-b),1.0);
  --bg-c: #{HexToRGB(#FCF3E6)}; // rgba(var(--bg-c),1.0);
  --bg-d: #{HexToRGB(#F6F5F7)}; // rgba(var(--bg-d),1.0);
  --bg-e: #{HexToRGB(#EDEFF2)}; // rgba(var(--bg-e),1.0);
  --bg-f: #{HexToRGB(#F1582E)}; // rgba(var(--bg-f),1.0);
  --bg-g: #{HexToRGB(#11a651)}; // rgba(var(--bg-g),1.0);
  --bg-h: #{HexToRGB(#4D4A49)}; // rgba(var(--bg-h),1.0);
  --bg-j: #{HexToRGB(#E9E8EB)}; // rgba(var(--bg-j),1.0);
  --bg-k: #{HexToRGB(#F8F7FA)}; // rgba(var(--bg-k),1.0);
  --bg-l: #{HexToRGB(#E1E3E6)}; // rgba(var(--bg-l),1.0);
  --bg-m: #{HexToRGB(#21AC5D)}; // rgba(var(--bg-m),1.0);
  --bg-n: #{HexToRGB(#D24219)}; // rgba(var(--bg-n),1.0);

// --------------- default border color color variable --------------
  --br-a: #E1E3E6; // var(--br-a);
  --br-b: #D9D5D4; // var(--br-b);
  --br-c: #F1582E; // var(--br-c);
  --br-d: #FCF3E6; // var(--br-d);
  --br-e: #EDEFF2; // var(--br-e);
  --br-f: #8E8B8A; // var(--br-f);

// --------------- default gradient color variable ---------------
--gr-a: linear-gradient(180deg, rgba(26, 26, 26, 0) 0%, #1a1a1a 100%); // var(--gr-a);
--gr-b: linear-gradient(180deg, rgba(26, 26, 26, 0) 0%, #1a1a1a 100%); // var(--gr-b);

 // --------------- loader variable -------------------------------
 --loader: linear-gradient(90deg, #57e06c 0%, rgba(255, 255, 255, 1) 45.5%, rgba(255, 255, 255, 1) 55.5%, #2ac441 100%); // var(--loader);
} 


