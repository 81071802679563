.footer {
  padding: clamp(40px, 3.8095vw, 48px) 0 clamp(32px, 3.1746vw, 40px);
  background:rgba(var(--bg-d),1.0);

  &__box {
    display: flex;
    flex-direction: column;
    gap: clamp(32px, 2.7778vw, 35px);
  }

  &__top{
    padding-bottom: clamp(32px, 2.7778vw, 35px);
    border-bottom: solid 1px var(--br-a);
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 22px;

    @include small-tablet{
      grid-template-columns: repeat(3, 1fr);
    }

    @include mobile{
      grid-gap: 24px;
      grid-template-columns: 100%;
    }
  }

  &__bottom {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr auto;
    grid-gap: 14px clamp(16px, 1.9048vw, 24px);

    p {
    color: rgba(var(--cl-b),.95);

    @include mobile{
      font-size: 16px;
    }
    }

    @include mobile{
      grid-gap: 24px;
      justify-items: center;
      grid-template-columns: 100%;

      .select{
        order: -1;
      }
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;

    &::before {
      content: attr(data-title);
      display: inline;
      font-family: var(--font-family);
      font-weight: 600;
      font-size: clamp(16px, 1.4286vw, 18px);
      line-height: 144%;
      color: rgba(var(--cl-b),.95);

      @include mobile{
        font-size: 18px;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 8px;

      a {
        transition: all 0.3s;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: clamp(14px, 1.2698vw, 16px);
        line-height: 150%;
        color: rgba(var(--cl-b),.95);

        &:hover {
          color: rgba(var(--cl-c),1.0);
        }
      }
    }
  }
}
