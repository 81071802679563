.reviews-section {
  margin-bottom: clamp(48px, 5.7143vw, 72px);

  &__box {
    display: grid;
    grid-template-columns: 1fr clamp(300px, 32.0635vw, 404px);
    gap: clamp(16px, 1.9048vw, 24px);
  
    @include tablet{
      grid-template-columns: 100%;
      gap: 48px;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__list{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__item{
    padding-bottom: 20px;
    border-bottom: solid 1px var(--br-a);
  }

  &__top {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    gap: clamp(24px, 2.8571vw, 36px);

    @include tablet{
      justify-content: space-between;
    }

    @include mobile{
      margin-bottom: 0;
    }
  }

  &__title {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: clamp(22px, 2.2222vw, 28px);
    line-height: 129%;
    color: rgba(var(--cl-b), 0.95);

    i {
      font-style: normal;
    }
  }

  .info-list{
    &::before{
      @include mobile{
        margin-bottom: 16px;
      }
    }
  }
}
