.interesting-card{
    display: flex;
    flex-direction: column;
    gap: 9px;
    width: 100%;

    &__image{
        width: 100%;
        height: 299px;
        overflow: hidden;
        border-radius: 8px;

        img,picture{
            transition: all .3s;
            width: 100%;
            height: 100%;
        }

        &:hover{
            img,picture{
                transform: scale(1.015);
            }
        }
    }

    &__title{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 18px;
        line-height: 144%;
        color:rgba(var(--cl-b),.95);
    }

    &__bottom{
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: space-between;
    }

   
}