.poster{
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px;

    .main-top{
        padding-bottom: 16px;
        grid-column: 1/-1;
        border-bottom: solid 1px var(--br-a);

        .main-link{
            margin-right: -13px;
        }
    }

    &__list{
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__item{
        width: 100%;
    }

    &__link{
        display: grid;
        align-items: center;
        grid-template-columns: auto 1fr auto;
        grid-gap: 16px;

        &:hover{
            .poster__title{
                color: rgba(var(--cl-c),1);
            }
        }
    }

    &__title{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.01em;
        transition: all .3s;
        color: rgba(var(--cl-b),.95);
    }

    &__box{
        display: flex;
        flex-direction: column;
    }

    &__day{
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 14px;
        line-height: 143%;
        text-align: right;
        color: rgba(var(--cl-b),.58);
    }

    &__date{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.01em;
        text-align: right;
        color: rgba(var(--cl-b),.95);
    }

    &__image{
        display: flex;
        width: 80px;
        height: 58px;

        img,picture{
            width: 100%;
            height: 100%;
        }
    }
}