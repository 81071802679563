.breadcrumbs{
    display: flex;
    align-items: center;
    gap: 4px;

    &__item{
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 14px;
        line-height: 143%;
        color: rgba(var(--cl-b),.58);
        white-space: nowrap;

        a{
            font-family: var(--font-family);
            font-weight: 400;
            font-size: 14px;
            line-height: 143%;
            color: rgba(var(--cl-b),.58);
            transition: all .3s;
            display: flex;
            align-items: center;
            gap: 4px;
            white-space: nowrap;

            &:hover{
                color: rgba(var(--cl-c),1.0);
            }

            &::after{
                content: '';
                width: 20px;
                height: 20px;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23000' fill-opacity='.38' d='M10.5 10 6.667 6.167 7.833 5l5 5-5 5-1.166-1.167L10.5 10Z'/%3E%3C/svg%3E");
                pointer-events: none;
            }
        }

        &:last-child{
            a{
                &::before{
                    content: none;
                }
            }
        }
    }

    @include small-tablet{
        overflow-x: auto;
        width: calc(100% + (var(--container-offset) * 2));
        padding: 0 var(--container-offset);
        margin-left: calc(var(--container-offset) * -1);
    }
}