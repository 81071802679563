.weather-section{
    margin-bottom: clamp(50px, 5.7143vw, 72px);

    &__box {
      display: flex;
      gap: clamp(25px, 2.5397vw, 32px);
      flex-direction: column;
    }

    &__top{
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__inner{
        display: grid;
        grid-template-columns: clamp(200px, 23.5714vw, 297px) 1fr;
        grid-gap: 24px clamp(16px, 1.9048vw, 24px);
    }

    &__info{
        display: grid;
        grid-template-columns: 1fr clamp(200px, 23.5714vw, 297px);
        grid-gap: 24px clamp(16px, 1.9048vw, 24px);
    }

    &__aside{
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__tabs{
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .weather-list{
        margin-top: 20px;
    }

    .main-top{
        align-items: flex-start;
        flex-direction: column;
        gap: clamp(25px, 2.5397vw, 32px);
    }
}