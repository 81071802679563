.around-section {
  margin-bottom: clamp(50px, 5.7143vw, 72px);

  &__box {
    display: grid;
    grid-template-columns: 1fr clamp(350px, 32.1429vw, 405px);
    grid-gap: 24px clamp(16px, 1.9048vw, 24px);

    @include tablet {
      grid-template-columns: 100%;
    }

    @include small-tablet {
      grid-gap: 16px;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include small-tablet {
      margin: 8px 0;
      order: -2;
    }

    @include mobile{
      margin-top: -142px;
    }
  }

  .main-top {
    grid-column: 1/-1;

    &__filter {
      @include small-tablet {
        display: contents;
      }
    }

    &__wrapp {
      @include small-tablet {
        order: -3;
      }

      @include mobile {
        overflow-x: auto;
        width: calc(100% + (var(--container-offset) * 2));
        margin-left: calc(var(--container-offset) * -1);
        padding: 1px var(--container-offset) 150px;
        position: relative;
        z-index: 25;

        .filter-select {
          min-width: initial;
          &:nth-child(3) {
            order: -1;
          }
        }
      
      }
    }

    @include small-tablet {
      display: contents;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        order: -4;
      }

      .main-link {
        order: -1;
        justify-self: center;
        margin-bottom: 24px;
      }
    }
  }

  &__map {
    width: clamp(350px, 32.1429vw, 405px);
    height: clamp(250px, 23.6508vw, 298px);
    display: flex;
    border-radius: 12px;
    overflow: hidden;

    img,
    picture,
    iframe {
      width: 100%;
      height: 100%;
    }

    @include tablet {
      width: 100%;
      height: 298px;
    }
  }

  &__aside {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    .main-link {
      &:not(:hover) {
        svg {
          fill: rgba(var(--cl-c), 1);
        }
      }

      svg {
        transition: all 0.3s;
      }
    }
  }
}
