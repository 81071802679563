.posters-section{
    margin-bottom: clamp(50px, 5.7143vw, 72px);

    &__top{
        display: flex;
        gap: 4px;
        flex-direction: column;
    }

    &__filters{
      display: flex;
      align-items: center;
      gap: 16px;

      .select{
        min-width: 84px;
      }
    }

    &__box {
      display: flex;
      gap: clamp(25px, 2.5397vw, 32px);
      flex-direction: column;
    }
}