.main-review{
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: clamp(12px, 1.2698vw, 16px);

    &__logo{
        border-radius: 100%;
        display: flex;
        overflow: hidden;
        width: clamp(48px, 4.7619vw, 60px);
        height: clamp(48px, 4.7619vw, 60px);

        svg,img{
            width: 100%;
            height: 100%;
        }
    }

    &__box{
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__content{
        display: flex;
        flex-direction: column;
        gap: 16px;

        p{
            font-size: 16px;
            color: rgba(var(--cl-b),.95);
        }
    }

    &__wrapp{
        display: flex;
        flex-wrap: wrap;
        gap: clamp(12px, 1.2698vw, 16px);

        img{
            border-radius: 8px;
            width: clamp(135px, 12.6984vw, 160px);
            height: clamp(94px, 8.8889vw, 112px);
        }
    }

    &__top{
        display: grid;
        grid-template-columns: 1fr auto;
        column-gap: clamp(16px, 1.9048vw, 24px);
    }

    &__name{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.01em;
        color: rgba(var(--cl-b),.95);
    }

    &__value{
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 14px;
        line-height: 143%;
        color: rgba(var(--cl-b),.58);

        i{
            font-style: normal;
        }
    }

    &__like{
        align-self: start;
        grid-column: 2/3;
        grid-row: 1/3;
        display: flex;
        align-items: center;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 14px;
        line-height: 143%;
        color: rgba(var(--cl-b),.38);
        gap: 8px;
        transition: all .3s;

        i{
            font-style: normal;
        }

        svg{
            margin-top: -2px;
            transition: all .3s;
            fill: #BFC4D0;
        }

        &:hover{
            color: rgba(var(--cl-c),1.0);

            svg{
                fill: rgba(var(--cl-c),1.0);
            }
        }
    }
}