.news-card{
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    &__title{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 18px;
        line-height: 144%;
        color: rgba(var(--cl-b),.95);
        transition: all .3s;
    }

    &__bottom{
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__date{
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 14px;
        line-height: 143%;
        color: rgba(var(--cl-b),.58);
    }

    &__label{
        font-family: var(--second-family);
        font-weight: 500;
        font-size: 14px;
        line-height: 143%;
        letter-spacing: 0.01em;
        text-align: center;
        color: rgba(var(--cl-b),.95);
        padding: 3px 10px;
        border-radius: 8px;
        background: rgba(var(--bg-c),1.0);
    }

    &:hover{
        .news-card__title{
            color: rgba(var(--cl-c),1.0);
        }
    }
}