.content-section{
    margin-bottom: 24px;

    &__box{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 24px;


        .news{
            grid-column: 2;
            grid-row: 1/3;
        }
    }
}