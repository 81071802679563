.page-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: clamp(10px, 1.4286vw, 18px);
  align-self: center;

    ul:not([class]) {
      flex-direction: row;
      grid-gap: clamp(10px, 1.4286vw, 18px);

      li{
        &::before{
          content: none;
        }

        @include mobile{
          &:nth-child(4){
            display: none;
          }
        }
      }
  }

  ul {
    display: flex;
    align-items: center;
    grid-gap: clamp(10px, 1.4286vw, 18px);
    flex-direction: row;
   
    li {
      width: 40px;
      display: flex;
      aspect-ratio: 1;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      color: rgba(var(--cl-f),1.0);
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      button, a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        transition: all 0.3s;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: rgba(var(--cl-f),1.0);
        z-index: 2;
        position: relative;
        border: solid 1px transparent;
        border-radius: 100%;

        &:hover {
          color: rgba(var(--cl-c),1.0);
        }

        &.active {
            pointer-events: none;
            color: rgba(var(--cl-c),1.0);
            border-color: rgba(var(--cl-c),1.0);
        }

        &:disabled {
          opacity: 0.6;
          pointer-events: none;
        }
      }
    }
  }

  &__btn {
    width: clamp(35px, 3.1746vw, 40px);
    aspect-ratio: 1;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    transition: all .3s;

    svg{
      color: rgba(var(--cl-e),1.0);
    }
   
    img,
    svg {
      transition: all .3s;
      display: flex;
      width: 20px;
      height: 20px;
      fill: none;
    }

    &.prev {
      img,
      svg {
        rotate: 180deg;
      }
    }

    &:hover {
        svg{
          color: rgba(var(--cl-c),1.0);
        }
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.6;
    }
  }
}
