.social{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: clamp(16px, 1.9048vw, 24px);
    border-radius: 12px;
    background: rgba(var(--bg-k),1.0);

    @include mobile{
        flex-wrap: wrap;
    }

    &__link{
        display: flex;
        align-items: center;
        gap: 10px;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: rgba(var(--cl-b),.95);
        transition: all .3s;

        &:hover{
            color: rgba(var(--cl-c),1.0);
            .social__icon{
                color: rgba(var(--cl-a),1.0);
                background: rgba(var(--bg-f),1.0);
            }
        }
    }

    &__item{
        @include mobile{
            width: calc(50% - 20px);
        }
    }

    &__icon{
        background: rgba(var(--bg-l),1.0);
        border-radius: 100%;
        width: clamp(35px, 3.1746vw, 40px);
        height: clamp(35px, 3.1746vw, 40px);
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(var(--cl-f),1.0);
        transition: all .3s;
       
        @include mobile{
            width: 40px;
            height: 40px;
        }
    }

   
}