.other-section{
    margin-bottom: clamp(50px, 5.7143vw, 72px);

    &__box {
      border-top: solid 1px var(--br-a);
      padding-top:clamp(50px, 5.7143vw, 72px);
      display: flex;
      gap: clamp(25px, 2.5397vw, 32px);
      flex-direction: column;
    }

    .main-top{
      h1,h2,h3,h4,h5,h6{
        font-size: clamp(26px, 2.8571vw, 36px);
        line-height: 122%;
      }
    }
}